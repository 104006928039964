import { LoadingButton } from '@mui/lab'
import { Box, Icon, Paper, TextField, Typography } from '@mui/material'
import creditCards from 'assets/animations/credit-cards.json'
import { BankInformation, UploadImage } from 'components'
import { useCart, useFeedback, useInvoiceContract, useProcess } from 'hooks'
import { getBankingInformationService, uploadReceiptService } from 'lib'
import { IBankingInformation, ITransaction } from 'marketplace-common'
import React from 'react'
import Lottie from 'react-lottie'
import biosferasoftLogoIcon from '../../../assets/img/logo-biosferasoft-icon.webp'

type Props = {
  transactions: ITransaction[]
  getTransactionsByProcessId: (processId: number) => Promise<ITransaction[]>
}

export const Transfer = (props: Props) => {
  const { transactions, getTransactionsByProcessId } = props
  const [showAnimation, setShowAnimation] = React.useState<boolean>(true)
  const [file, setFile] = React.useState<File>()
  const [bankInformation, setBankInformation] = React.useState<IBankingInformation[]>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [transactionComment, setTransactionComment] = React.useState<string>('')
  const { invoiceDetails, handleInvoiceDetails } = useInvoiceContract()
  const { productsIds, specialProductsId } = useCart()

  const { showMessage } = useFeedback()
  const { process } = useProcess()

  const handleGetBankingInformation = async () => {
    const resInformation = await getBankingInformationService()
    setBankInformation(resInformation)
  }

  React.useEffect(() => {
    handleGetBankingInformation()
    const handleGetInvoiceDetails = async () => {
      await handleInvoiceDetails(
        productsIds,
        specialProductsId,
        process?.promotionalCode?.promotionalCodeId
      )
    }
    handleGetInvoiceDetails()
    setTimeout(() => {
      setShowAnimation(false)
    }, 2000)
  }, [])

  const handleSendVoucher = async (formData: FormData) => {
    await uploadReceiptService(formData)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (file) {
        setLoading(true)
        const formData = new FormData()
        formData.append('voucher', file)
        formData.append('transactionId', String(transactions[0].transactionId))
        formData.append('transactionComment', transactionComment)
        formData.append('processId', String(process?.processId))
        await handleSendVoucher(formData)
        if (process) {
          await getTransactionsByProcessId(process.processId)
        }
      }
      showMessage('Comprobante enviado', 'success')
    } catch (error: any) {
      showMessage(error.message ?? 'Error al enviar el comprobante', 'error')
    } finally {
      setFile(undefined)
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ opacity: showAnimation ? 0 : 1, width: '100%' }}>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pb: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <Box sx={{ display: 'flex', gap: 1.4, alignItems: 'center' }}>
              <img
                src={biosferasoftLogoIcon}
                alt={'Biosferasoft'}
                width="26px"
                height="42px"
                loading="lazy"
              />

              <Typography
                variant="caption"
                sx={{ fontSize: { xs: 15, sm: 15 }, fontWeight: 700, lineHeight: 1.2 }}
              >
                {'Datos de la transferencia'}
              </Typography>
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    sx={{ fontSize: { xs: 12, sm: 15 } }}
                  >
                    {`Monto a transferir`} <strong>{invoiceDetails.total ?? 0}</strong>
                  </Typography>
                </Box>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: '5px',
                    py: { xs: 1.2 },
                    width: { xs: 240, md: 200 },
                    fontSize: { xs: 12, md: 14, lg: 16 },
                  }}
                  disabled={loading || file === undefined}
                  loading={loading}
                >
                  <Typography variant="caption" fontWeight={600} sx={{ fontSize: { xs: 12 } }}>
                    {` Enviar Comprobante `}
                  </Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Box>

          <Box sx={{ paddingX: 2 }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-evenly',
                gap: { xs: 4, sm: 5, md: 6 },
              }}
            >
              {bankInformation?.map((bankInfo, index) => (
                <BankInformation bankInformation={bankInfo} />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              paddingX: 2,
              pt: 3,
              gap: 2,
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', maxHeight: 200 }}>
              <TextField
                id="comment"
                label="Comentario (opcional)"
                name="transactionComment"
                multiline
                rows={5}
                maxRows={5}
                variant="outlined"
                value={transactionComment}
                placeholder="Se aconseja incluir tu número celular y correo para contactarnos"
                onChange={(e) => setTransactionComment(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    height: '200px',
                    width: '100%',
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <UploadImage
                file={file}
                title="Comprobante de pago"
                typeDescription="PNG , JPG o JPEG"
                onFileAdded={(file?: File) => {
                  if (file) {
                    setFile(file)
                  } else {
                    setFile(undefined)
                  }
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column' },
                gap: 0.8,
                paddingTop: 2,
                paddingX: 2,
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Icon sx={{ fontSize: 25 }}>local_atm</Icon>
                <Typography variant="caption" fontWeight={600} fontSize={15}>
                  {`Monto a transferir `} <strong>{invoiceDetails.total ?? 0}</strong>
                </Typography>
              </Box>

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: '5px',
                  px: { xs: 3, sm: 2 },
                  py: { xs: 1.2 },
                  gap: { xs: 1 },
                }}
                disabled={loading || file === undefined}
                loading={loading}
              >
                {' Enviar Comprobante'}
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>

      {showAnimation && (
        <Box
          sx={{
            display: 'flex',
            top: -10,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            bgcolor: (theme) => theme.palette.alt?.background,
          }}
        >
          <Lottie
            width={300}
            height={300}
            options={{
              animationData: creditCards,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
