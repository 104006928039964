import { LoadingButton } from '@mui/lab'
import { Backdrop, Badge, Box, Icon, IconButton, List, Menu, Typography } from '@mui/material'
import { CleanItemsBtn, ProductsDetails } from 'components'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useAuth,
  useCart,
  useCreateProcess,
  useInvoiceContract,
  useLocalStorage,
  useNavigate,
  useProcess,
} from 'hooks'
import {
  PROCESS_ID_PARAM,
  ROUTES,
  SEARCH_PARAM,
  constLocalStorage,
  createProcessesService,
} from 'lib'
import { ICartProduct, IPromocionalCode } from 'marketplace-common'
import React from 'react'
import { StepReferenceName, TransactionStateId } from 'types'
import { encryptParam } from 'utils'

type Props = {
  cart: ICartProduct[] | undefined
  nonEmptyCart: boolean
  disableContinue?: boolean
  hideButton?: boolean
  noDeleteInPickProduct?: boolean
  handleBtnContinue?: () => Promise<void> | void
}

/**
 *
 * @param props cart: array of main solutions added by the user
 * onRemove: fired callback when the user removes a solution from the cart
 * nonEmptyCart: boolean used to know if the cart is empty or not
 * @returns Component with shopping cart functionalities
 */
export const ShoppingCart = (props: Props) => {
  const {
    cart,
    nonEmptyCart,
    disableContinue,
    hideButton,
    noDeleteInPickProduct,
    handleBtnContinue,
  } = props
  const { loading } = useCreateProcess()
  const { user, isAuthenticated } = useAuth()
  const { animatedNavigation } = useNavigate()
  const { getItem } = useLocalStorage()
  const {
    productsIds,
    specialProductsId,
    process,
    applyCodeFlag,
    getProcessByCompanyIdentification,
  } = useCart()
  const { invoiceDetails, handleInvoiceDetails } = useInvoiceContract()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const { currentStep } = useProcess()
  const [loadingBtn, setLoadingBtn] = React.useState(false)

  const handleShop = async () => {
    try {
      const process = await handleCreateProcess()
      if (process) {
        const processId = encryptParam(process?.processId.toString())
        const route = `${ROUTES.processes}/${'nuna'}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
        animatedNavigation(route)
        setLoadingBtn(true)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShopNotLogged = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(processNoLoggedRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreateProcess = async () => {
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    const response = await createProcessesService(process)
    return response
  }

  const getInvoiceDetails = React.useCallback(
    async (promotionalCodeId?: number) => {
      await handleInvoiceDetails(productsIds, specialProductsId, promotionalCodeId)
    },
    [productsIds, specialProductsId]
  )

  React.useEffect(() => {
    if (isAuthenticated) {
      getInvoiceDetails(process?.promotionalCode?.promotionalCodeId)
    } else {
      const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (promotionalCode !== undefined) {
        getInvoiceDetails(promotionalCode.promotionalCodeId)
      }
    }
  }, [getInvoiceDetails, getItem, isAuthenticated, process])

  React.useEffect(() => {
    if (applyCodeFlag) {
      const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (promotionalCode !== undefined) {
        getInvoiceDetails(promotionalCode.promotionalCodeId)
      }
    } else {
      getInvoiceDetails()
    }
  }, [applyCodeFlag, getInvoiceDetails, getItem])

  const stateId = React.useMemo(() => {
    if (!process) return
    const lastElementHistory = [...process.history].pop()
    return lastElementHistory?.state.transactionStateId
  }, [process])

  const noAllowToCancelProcess = React.useMemo(() => {
    return stateId === TransactionStateId.COMPLETADO || stateId === TransactionStateId.PENDIENTE
  }, [stateId])

  React.useEffect(() => {
    getProcessByCompanyIdentification()
  }, [])

  return (
    <React.Fragment>
      {!hideButton && (
        <AnimatePresence>
          {nonEmptyCart && !disableContinue && (
            <LoadingButton
              loading={loadingBtn}
              onClick={isAuthenticated ? handleShop : handleShopNotLogged}
              component={motion.div}
              variant="contained"
              initial={{
                x: -300,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              sx={{
                lineHeight: { xs: 1.2, sm: 1.75 },
                px: 2,
                pb: 1,
                mr: 1,
                mb: { xs: 1.5, sm: 0 },
                fontSize: { xs: 12, sm: 13 },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'Continuar Compra'}
              <Icon sx={{ fontSize: { xs: 13, sm: 17 } }}>navigate_next</Icon>
            </LoadingButton>
          )}
        </AnimatePresence>
      )}
      <Badge
        className="shopping-car-icon-tour-steps"
        badgeContent={cart?.length}
        color="secondary"
        showZero
      >
        <IconButton
          size="medium"
          sx={{ bgcolor: (theme) => `${theme.palette.primary.main}20` }}
          onClick={handleClick}
          disabled={loading}
        >
          <Icon fontSize="medium">shopping_bag_outlined</Icon>
        </IconButton>
      </Badge>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginLeft: -0.5,
            width: { xs: '342px !important', sm: '570px !important', md: '650px !important' },
            minWidth: { xs: '342px !important', sm: '570px !important', md: '650px !important' },
            height: 'auto',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 30,
              right: -4,
              width: 25,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <List dense sx={{ width: { xs: 342, sm: 570, md: 650 }, height: 'auto', pt: 1 }}>
          {nonEmptyCart && (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              fontWeight={600}
              color="textSecondary"
              variant="caption"
              align="center"
            >
              {'Items agregados'}
            </Typography>
          )}

          {nonEmptyCart && cart ? (
            <Box sx={{ paddingX: 1 }}>
              <ProductsDetails
                invoiceDetail={invoiceDetails}
                disableDelete={
                  isAuthenticated
                    ? Boolean(noDeleteInPickProduct && cart.length === 1) || noAllowToCancelProcess
                    : false
                }
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} gap={1}>
                {currentStep?.reference !== StepReferenceName.ACCOUNT &&
                  currentStep?.reference !== StepReferenceName.PAYMENT && (
                    <CleanItemsBtn
                      isAuth={isAuthenticated}
                      processId={process?.processId}
                      noAllowToCleanItems={noAllowToCancelProcess}
                    />
                  )}

                <LoadingButton
                  disabled={loading}
                  loading={loading}
                  endIcon={<Icon>chevron_right</Icon>}
                  onClick={async () => {
                    if (handleBtnContinue !== undefined) {
                      await handleBtnContinue()
                    } else {
                      handleShop()
                    }
                  }}
                  component={motion.div}
                  variant="contained"
                  initial={{
                    x: -300,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  sx={{ px: 2, pb: 1, mr: 1 }}
                >
                  {'Continuar'}
                </LoadingButton>
              </Box>
            </Box>
          ) : (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              fontWeight={600}
              color="textSecondary"
              variant="caption"
              align="center"
            >
              {'No tienes items agregados'}
            </Typography>
          )}
        </List>
      </Menu>
    </React.Fragment>
  )
}
