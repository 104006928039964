import { LoadingButton } from '@mui/lab'
import { Box, Modal, Typography } from '@mui/material'
import { SignUpForm } from 'components'
import { LoginForm } from 'components/auth/LoginForm'
import { useAuth, useFeedback, useProcess, useProcessSelection } from 'hooks'
import { GA_EVENT, ROUTES, getProcessesByCompanyIdService } from 'lib'
import { IProcess, IUser } from 'marketplace-common'
import { VerificationSent } from 'pages'
import { IUserRequest } from 'types'
import React from 'react'
import { format } from 'date-fns'
import { MAIN_COLORS } from 'styles'
import { Options } from 'types'
import { trackGoogleAnalyticsEventWithData } from 'utils'

type Props = {
  open: boolean
  initializeConfigureAfterLogin: (user: IUser) => Promise<void>
  loadingInitialize: boolean
  handleOpenDialog: () => void
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 232,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: '85vh',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const ModelOtherAccount = (props: Props) => {
  const { open, initializeConfigureAfterLogin, handleOpenDialog } = props
  const { isAuthenticated, login } = useAuth()
  const { handleCloseDialog } = useProcessSelection()
  const { changeStepIndex } = useProcess()
  const { showMessage } = useFeedback()
  const [option, setOption] = React.useState<Options>(ROUTES.login as Options)
  const [openVerification, setOpenVerification] = React.useState<boolean>(false)
  const [dataUser, setDataUser] = React.useState<IUserRequest>({} as IUserRequest)

  const getDataUser = (user: IUserRequest) => {
    setDataUser({ ...user })
  }

  const handleToPickProduct = () => {
    changeStepIndex(0)
  }

  const handleChangeOption = (option: Options) => {
    setOption(option)
  }

  const handleOpenVerification = (value: boolean) => {
    setOpenVerification(value)
  }

  const onClickLogin = async (email: string, password: string) => {
    try {
      const user = await login(email, password, false, '')
      handleCloseDialog('login')
      if (user) {
        const result: IProcess[] = await getProcessesByCompanyIdService(user.identification)
        const existProcess = result.length > 0
        const gaData = {
          user_id: user.userId.toString(),
          company_id: user.identification,
          login_date: format(new Date(), 'dd-MM-yyyy'),
        }
        trackGoogleAnalyticsEventWithData('Button', GA_EVENT.LOGIN_EVENT, 'success login', gaData)
        if (existProcess) {
          handleOpenDialog()
        } else {
          await initializeConfigureAfterLogin(user)
        }
      }
    } catch (error: any) {
      showMessage(error.message as string, 'error', { horizontal: 'right', vertical: 'top' })
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {option === 'login' && (
          <>
            {!isAuthenticated && (
              <Typography mt={1} align="center" fontWeight={600}>
                Para continuar con el pago, inicie sesión
              </Typography>
            )}
            <LoginForm
              onClickLogin={onClickLogin}
              stylized={true}
              handleChangeOption={handleChangeOption}
              fromModal={true}
              dataUser={dataUser}
            />
          </>
        )}
        {option === ROUTES.signUp && (
          <SignUpForm
            handleChangeOption={handleChangeOption}
            fromModal={true}
            handleOpenVerification={handleOpenVerification}
            getDataUser={getDataUser}
          />
        )}
        {option === 'verification' && openVerification && (
          <VerificationSent
            emailParam={dataUser.email}
            handleChangeOption={handleChangeOption}
            fromModal={true}
          />
        )}

        {option !== 'verification' && (
          <LoadingButton
            sx={{
              background: MAIN_COLORS.primary.main,
              color: 'white',
              '&:hover': {
                backgroundColor: '#1c1c1c',
              },
              '&:active': {
                backgroundColor: '#1c1c1c',
              },
            }}
            fullWidth
            type="submit"
            size="medium"
            onClick={() => handleToPickProduct()}
          >
            {'Ir a productos'}
          </LoadingButton>
        )}
      </Box>
    </Modal>
  )
}
