import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { CleanItemsBtn, LoginForm, ProcessToolbar, ProductsDetails, SignUpForm } from 'components'
import format from 'date-fns/format'
import {
  useAuth,
  useCart,
  useCompanyInformation,
  useFeedback,
  useGetSolutions,
  useInvoiceContract,
  useLocalStorage,
  useProcess,
  useTransaction,
  useValidateCompanyInformation,
} from 'hooks'
import { useProcessSelection } from 'hooks/processSelection/useProcessSelection'
import {
  PROCESS_ID_PARAM,
  ROUTES,
  SEARCH_PARAM,
  changeCompanyIdToRucService,
  constLocalStorage,
  copyProcessService,
  getCompanyInformationByIdService,
  getProcessesByCompanyIdService,
  getProductsValidateRucService,
  validateIdentificationCard,
  validateSingleRegistryOfTaxpayers,
} from 'lib'
import {
  ICompanyInformation,
  IProduct,
  IPromocionalCode,
  ISpecialProduct,
} from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLORS } from 'styles'
import { IUserRequest, Options, StepReferenceName } from 'types'
import { encryptParam } from 'utils'
import { ModelOtherAccount } from '../account/ModelOtherAccount'

type LoadOptions =
  | 'noSavingLoading'
  | 'savingLoading'
  | 'loading'
  | 'loadingContinue'
  | 'loadingConfirmProductsNeedsRuc'

type RucInformation = { newRuc: string; companyName: string }

export const InvoiceForm = () => {
  const navigate = useNavigate()
  const { user, isAuthenticated, login } = useAuth()
  const { getItem, storeItem, deleteItem } = useLocalStorage()
  const { getTransactionsByProcessId, handleSaveTransaction } = useTransaction()
  const { palette, breakpoints } = useTheme()
  const { getLogo } = useGetSolutions()
  const { showMessage } = useFeedback()
  const { handleOpenDialog, handleCloseDialog, openDialog, handleCloseAllDialog } =
    useProcessSelection()

  const [load, setLoad] = React.useState<LoadOptions[]>([])
  const [option, setOption] = React.useState<Options>(ROUTES.signUp as Options)
  const [productsNeedsRuc, setProductsNeedsRuc] = React.useState<IProduct[]>([])
  const [dataUser, setDataUser] = React.useState<IUserRequest>({} as IUserRequest)
  const [rucInformation, setRucInformation] = React.useState<RucInformation>({} as RucInformation)
  const [specialProductsNeedsRuc, setSpecialProductsNeedsRuc] = React.useState<ISpecialProduct[]>(
    []
  )

  const handleLoad = (dialogId: LoadOptions) => setLoad((prev) => [...prev, dialogId])
  const handleCloseLoad = (load: LoadOptions) =>
    setLoad((prev) => prev.filter((option) => option !== load))

  const { invoiceDetails, handleInvoiceDetails, handleSubmitInvoiceContract, loadingInvoice } =
    useInvoiceContract()

  const {
    process: processInfo,
    switchNextStep,
    handleAssignedCompanyInformationIdToProcess,
    addEnableSteps,
    handleValidateCodeLoged,
    handleValidateCodeNotLoged,
    handleRemoveCode,
    loadingValidate,
    code,
    handleChangeCode,
    shopToAnotherAccount,
    handleAssignedCompanyToProcess,
    loadingInitialize,
    initializeConfigureAfterLogin,
  } = useProcess()
  const {
    fieldErrors,
    setFieldErrors,
    setCompanyInformation: setCompanyInformationToValidate,
    validateCompanyInformation,
  } = useValidateCompanyInformation()
  const {
    nonEmptyCart,
    productsIds,
    specialProductsId,
    specialProductsIdFromLocalStorage,
    productsIdsFromLocalStorage,
    specialProductExpired,
    loadingShoppingCart,
    handleChangeApplyCodeFlag,
  } = useCart()
  const {
    companyInformation,
    changeCompanyInformation,
    alreadyExistsCompanyInformation,
    changeAlreadyExistsCompanyInformation,
    handleSaveCompanyInformationDefault,
    handleSaveCompanyInformationDisabled,
    handleSaveCompanyInformationEnabled,
    companyInformationList,
    handleCompanyInformationList,
  } = useCompanyInformation()

  const isMobile = useMediaQuery(breakpoints.down('md'))
  const loading = load.includes('loading') || loadingInvoice
  const loadingContinue = load.includes('loadingContinue')
  const noSavingLoading = load.includes('noSavingLoading')
  const savingLoading = load.includes('savingLoading')
  const loadingConfirmProductsNeedsRuc = load.includes('loadingConfirmProductsNeedsRuc')

  const handleChangeOption = (option: Options) => {
    setOption(option)
  }

  const getDataUser = (user: IUserRequest) => {
    setDataUser({ ...user })
  }

  const companyInformationEnable = React.useMemo(() => {
    const existCompanyInformationList = Boolean(companyInformationList)
    if (existCompanyInformationList) {
      return companyInformationList!.filter((comp) => comp.enable)
    }
    return []
  }, [companyInformationList])

  const loadingAdd = React.useMemo(
    () =>
      loadingShoppingCart.specialProductId.length > 0 || loadingShoppingCart.productId.length > 0,
    [loadingShoppingCart]
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const existsProcessId = Boolean(processInfo?.processId)
  const existsCompanyId = Boolean(user?.identification)

  const openCompanyInformationMenu = Boolean(anchorEl)
  const existsCompanyInformationList = Boolean(
    companyInformationList?.length &&
      companyInformationList.some((company) => company.enable && company.default)
  )
  const existsMoreThanOneCompanyInformation = companyInformationList?.length >= 0

  const amount = process.env.REACT_APP_MINIMUM_AMOUNT
  const minimumAmount = amount !== undefined ? parseFloat(amount) : 0

  const handleOpenCompanyInformationMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseCompanyInformationMenu = () => setAnchorEl(null)

  const handleComplete = (e: React.FormEvent) => {
    e.preventDefault()
  }

  const codeValid = React.useMemo(() => {
    return processInfo?.promotionalCode !== null ? true : false
  }, [processInfo])

  const handleSaveInvoiceContractAndTransaction = async () => {
    const existsProcessId = Boolean(processInfo?.processId)
    if (!existsProcessId) {
      return
    }
    try {
      const transactionArray = await getTransactionsByProcessId(processInfo?.processId!)
      const currentTransaction = transactionArray[0]
      const invoiceContract = await handleSubmitInvoiceContract(
        processInfo?.processId!,
        currentTransaction?.invoiceContractId ?? null,
        codeValid
      )
      await handleSaveTransaction(
        invoiceContract.invoiceContractId,
        processInfo!,
        currentTransaction
      )
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleValidateSpecialProductsExpired = () => {
    if (specialProductExpired !== null) {
      showMessage(
        `Tienes un producto en tu carrito que ya no se encuentra disponible: ${specialProductExpired.name}`,
        'error',
        {
          horizontal: 'right',
          vertical: 'top',
        }
      )
    } else {
      return
    }
  }

  const handleBtnContinueLogged = async () => {
    handleLoad('loadingContinue')
    const userIdIsRuc = validateIdentificationIsRuc(user.identification)
    if (!userIdIsRuc) {
      const existsProductsNeedsRuc = await validateProductsOnlyRuc(processInfo?.processId!)
      if (existsProductsNeedsRuc) {
        handleCloseLoad('loading')
        return
      }
    }
    handleValidateSpecialProductsExpired()
    const fieldsAreOk: boolean = validateCompanyInformation()
    const totalInvoice = parseFloat(invoiceDetails.total.substring(1))
    const existsProducts =
      invoiceDetails.products.length !== 0 || invoiceDetails.specialProducts.length !== 0
    if (!existsProducts) {
      showMessage('Debes tener al menos un producto en tu carrito', 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (specialProductExpired === null) {
      if (totalInvoice < minimumAmount) {
        showMessage(`La compra mínima es de $ ${minimumAmount}`, 'error', {
          horizontal: 'right',
          vertical: 'top',
        })
      } else if (!fieldsAreOk) {
        showMessage(`Completa todos los campos del formulario`, 'error', {
          horizontal: 'right',
          vertical: 'top',
        })
      } else {
        try {
          handleLoad('loading')
          const existsCompanyInformationId: boolean = Boolean(
            companyInformation.companyInformationId
          )
          if (!existsCompanyInformationId) {
            handleOpenDialog('saveInformation')
            return
          }
          const process = await handleAssignedCompanyInformationIdToProcess(
            companyInformation.companyInformationId
          )
          await handleSaveInvoiceContractAndTransaction()
          if (!shopToAnotherAccount) {
            await handleAssignedCompanyToProcess(user.identification, process)
          }
          ReactPixel.track('paymentInformationForm', companyInformation)
          addEnableSteps(StepReferenceName.INVOICE)
          nonEmptyCart && switchNextStep(shopToAnotherAccount)
        } catch (error) {
          console.log('Error', error)
        } finally {
          handleCloseLoad('loading')
        }
      }
    }
    handleCloseLoad('loadingContinue')
  }

  const validateIdentificationIsRuc = (identification: string) => {
    const isRuc =
      identification.length === 13 &&
      identification.slice(Math.max(identification.length - 3, 0)) === '001'

    return isRuc
  }

  const validateProductsOnlyRuc = async (processId: number) => {
    handleLoad('loading')
    const productsNeedsRuc = await getProductsValidateRucService(processId)
    const existsProductsNeedsRuc = Boolean(productsNeedsRuc) && productsNeedsRuc.length !== 0
    if (existsProductsNeedsRuc) {
      const products = productsNeedsRuc
        .map((item) => {
          const existsProduct = Boolean(item.product)
          if (existsProduct) {
            return item.product
          }
        })
        .filter((product) => Boolean(product)) as IProduct[]
      setProductsNeedsRuc(products)

      const specialProducts = productsNeedsRuc
        .map((item) => {
          const existsSpecialProduct = Boolean(item.specialProduct)
          if (existsSpecialProduct) {
            return item.specialProduct
          }
        })
        .filter((product) => Boolean(product)) as ISpecialProduct[]
      setSpecialProductsNeedsRuc(specialProducts)

      validateIdentificationCard(user.identification)
        ? setRucInformation({ newRuc: user.identification + '001', companyName: user.userName })
        : setRucInformation({ newRuc: '', companyName: user.userName })

      handleOpenDialog('productsNeedsRuc')
    }
    return existsProductsNeedsRuc
  }

  const handleChangeIdentificationToRuc = async () => {
    handleLoad('loadingConfirmProductsNeedsRuc')
    try {
      await changeCompanyIdToRucService(
        user.identification,
        rucInformation.newRuc,
        rucInformation.companyName
      )
      window.location.reload()
    } catch (error) {
      handleCloseDialog('confirmProductsNeedsRuc')
      handleCloseDialog('productsNeedsRuc')
      handleCloseLoad('loadingConfirmProductsNeedsRuc')
      showMessage(
        'Se produjo un error al intentar cambiar la identificación a RUC, por favor comuníquese con soporte para ayuda.',
        'error'
      )
      console.error(error)
    }
  }

  const handleBtnContinueNoLogged = () => {
    handleValidateSpecialProductsExpired()
    const fieldsAreOk: boolean = validateCompanyInformation()
    const totalInvoice = parseFloat(invoiceDetails.total.substring(1))
    const existsProducts =
      invoiceDetails.products.length !== 0 || invoiceDetails.specialProducts.length !== 0
    if (!existsProducts) {
      showMessage('Debes tener al menos un producto en tu carrito', 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (totalInvoice < minimumAmount) {
      showMessage(`La compra mínima es de $ ${minimumAmount}`, 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else if (!fieldsAreOk) {
      showMessage(`Completa todos los campos del formulario`, 'error', {
        horizontal: 'right',
        vertical: 'top',
      })
    } else {
      addEnableSteps(StepReferenceName.INVOICE)
      nonEmptyCart && handleOpenDialog('login')
    }
  }

  const handleBtnSaveAndContinue = async () => {
    try {
      handleLoad('savingLoading')
      if (!alreadyExistsCompanyInformation) {
        const result = await handleSaveCompanyInformationDefault()
        await handleAssignedCompanyInformationIdToProcess(result)
      } else {
        const result = await handleSaveCompanyInformationEnabled()
        await handleAssignedCompanyInformationIdToProcess(result)
      }
      await handleSaveInvoiceContractAndTransaction()
      handleCloseAllDialog()
      addEnableSteps(StepReferenceName.INVOICE)
      switchNextStep(shopToAnotherAccount)
    } catch (error) {
      console.log('Error', error)
    } finally {
      handleCloseLoad('savingLoading')
    }
  }

  const handleBtnNotSave = async () => {
    try {
      handleLoad('noSavingLoading')
      const result = await handleSaveCompanyInformationDisabled()
      await handleAssignedCompanyInformationIdToProcess(result)
      await handleSaveInvoiceContractAndTransaction()
      handleCloseAllDialog()
      addEnableSteps(StepReferenceName.INVOICE)
      switchNextStep(shopToAnotherAccount)
    } catch (error) {
      console.log('Error', error)
    } finally {
      handleCloseLoad('noSavingLoading')
    }
  }

  const loadCompanyInformation = async () => {
    const existsCompanyInformationInProcess = Boolean(processInfo?.companyInformationId)
    if (!existsCompanyInformationInProcess) return

    try {
      const result = await getCompanyInformationByIdService(processInfo?.companyInformationId!)
      changeCompanyInformation(result)
    } catch (error) {
      console.error(error)
    }
  }

  const isIdentificationRucOrCi = () => {
    const existsUserId = Boolean(user.userId)
    if (existsUserId) {
      const validateIdIsRucOrCi =
        validateIdentificationCard(user?.identification) ||
        validateSingleRegistryOfTaxpayers(user?.identification)
      return validateIdIsRucOrCi
    }
  }

  React.useEffect(() => {
    if (existsCompanyInformationList) {
      const enableList = companyInformationList.some((company) => company.enable && company.default)
      if (enableList && !Boolean(processInfo?.companyInformation)) {
        const companyInformation = companyInformationList.find(
          (company) => company.enable && company.default
        )
        changeCompanyInformation(companyInformation!)
      }
    }
  }, [existsCompanyInformationList])

  React.useEffect(() => {
    if (existsCompanyId) {
      handleCompanyInformationList(user.identification)
    }
    if (existsProcessId) {
      loadCompanyInformation()
    }
  }, [])

  React.useEffect(() => {
    const existsInvoiceDetails =
      Boolean(invoiceDetails.products) || Boolean(invoiceDetails.specialProducts)
    if (!existsInvoiceDetails) {
      handleLoad('loading')
      return
    }
    handleCloseLoad('loading')
  }, [invoiceDetails])

  React.useEffect(() => {
    const companyInformation = getItem(constLocalStorage.COMPANY_INFO)
    if (Boolean(companyInformation)) {
      changeCompanyInformation(companyInformation)
    }
  }, [])

  const isLoading = React.useMemo(() => {
    return loading || loadingAdd || loadingValidate
  }, [loading, loadingAdd, loadingValidate])

  const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode

  const promotionalCodeLocal = React.useMemo(() => {
    if (!isAuthenticated && promotionalCode !== undefined) {
      return promotionalCode
    } else {
      return undefined
    }
  }, [isAuthenticated, promotionalCode])

  const promotionalCodeActive = React.useMemo(() => {
    if (isAuthenticated) {
      return processInfo !== undefined && processInfo.promotionalCode !== null
    } else {
      if (promotionalCodeLocal !== undefined) {
        return true
      } else {
        return false
      }
    }
  }, [isAuthenticated, processInfo, promotionalCodeLocal])

  const existPromotionalCodeAndProcess: boolean = React.useMemo(() => {
    return code !== undefined && code.length > 0 && processInfo !== undefined
  }, [code, processInfo])

  const isCode: boolean = React.useMemo(() => {
    return code !== undefined && code.length > 0
  }, [code])

  React.useEffect(() => {
    const loadInvoiceDetails = getItem('loadInvoiceDetails')
    if (loadInvoiceDetails) {
      return
    }
    const productsId = existsProcessId ? productsIds : productsIdsFromLocalStorage
    const specialProductIds = existsProcessId
      ? specialProductsId
      : specialProductsIdFromLocalStorage
    if (isAuthenticated) {
      handleInvoiceDetails(
        productsId,
        specialProductIds,
        processInfo?.promotionalCode?.promotionalCodeId
      )
    } else {
      handleInvoiceDetails(productsId, specialProductIds, promotionalCodeLocal?.promotionalCodeId)
    }
  }, [
    existsProcessId,
    productsIds,
    specialProductsId,
    productsIdsFromLocalStorage,
    specialProductsIdFromLocalStorage,
    processInfo,
    isAuthenticated,
  ])

  React.useEffect(() => {
    changeAlreadyExistsCompanyInformation(existsCompanyInformationList)
  }, [existsCompanyInformationList])

  React.useEffect(() => {
    setCompanyInformationToValidate(companyInformation)
  }, [companyInformation])

  React.useEffect(() => {
    if (!isAuthenticated) {
      const companyInformationLocal = getItem(constLocalStorage.COMPANY_INFO)
      if (Boolean(companyInformationLocal)) {
        changeCompanyInformation(companyInformationLocal)
      }
    }
  }, [isAuthenticated, getItem])

  const handleValidateApplyCode = async () => {
    if (isAuthenticated) {
      const response = await handleValidateCodeLoged(existPromotionalCodeAndProcess, code)
      if (response?.valid) {
        ReactPixel.track('addPromotionalCode', {
          userId: Boolean(user.userId) ? user.userId : null,
          promotionalCode: code,
          productId: Boolean(response.promotionalCode?.products)
            ? response.promotionalCode?.products
            : null,
          promotionId: Boolean(response.promotionalCode?.specialProducts)
            ? response.promotionalCode?.specialProducts
            : null,
        })
      }
    } else {
      const response = await handleValidateCodeNotLoged(isCode, code)
      if (response?.valid) {
        savePromotionalCode(response.promotionalCode)
        const productsId = existsProcessId ? productsIds : productsIdsFromLocalStorage
        const specialProductIds = existsProcessId
          ? specialProductsId
          : specialProductsIdFromLocalStorage
        handleInvoiceDetails(
          productsId,
          specialProductIds,
          response.promotionalCode.promotionalCodeId
        )
        handleChangeApplyCodeFlag(true)

        ReactPixel.track('addPromotionalCode', {
          promotionalCode: code,
          productId: Boolean(response.promotionalCode?.products)
            ? response.promotionalCode?.products
            : null,
          promotionId: Boolean(response.promotionalCode?.specialProducts)
            ? response.promotionalCode?.specialProducts
            : null,
        })
      }
    }
  }

  const handleRemoveApplyCode = async () => {
    if (isAuthenticated) {
      await handleRemoveCode()
      handleChangeCode('')
    } else {
      deleteItem(constLocalStorage.PROMOTIONAL_CODE)
      const productsId = existsProcessId ? productsIds : productsIdsFromLocalStorage
      const specialProductIds = existsProcessId
        ? specialProductsId
        : specialProductsIdFromLocalStorage
      handleInvoiceDetails(productsId, specialProductIds)
      handleChangeApplyCodeFlag(false)
      handleChangeCode('')
    }
  }

  const onClickLogin = async (email: string, password: string) => {
    try {
      const userResponse = await login(email, password, false, '')
      const existsUser = Boolean(userResponse)

      if (existsUser) {
        handleCloseDialog('productsNeedsRuc')

        const result = await getProcessesByCompanyIdService(userResponse.identification)
        const processId = encryptParam(result[0]?.processId?.toString())
        const encryptRoute = `/${ROUTES.processes}/nuna${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
        navigate(encryptRoute)
      }
    } catch (error: any) {
      showMessage(error.message as string, 'error')
      handleChangeOption('login')
    }
  }

  const onClickSignUp = async (userResponse: IUserRequest) => {
    try {
      const existsProcessId = Boolean(processInfo?.processId)
      if (existsProcessId) {
        await copyProcessService(processInfo?.processId!, userResponse.identification)
        handleChangeOption('login')
      }
    } catch (error: any) {
      showMessage(error.message as string, 'error')
    }
  }

  const savePromotionalCode = (promotionalCode: IPromocionalCode) => {
    storeItem(constLocalStorage.PROMOTIONAL_CODE, promotionalCode)
  }

  const codeLocal = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
  const getCode = React.useMemo(() => {
    if (isAuthenticated && processInfo?.promotionalCode?.code) {
      const processPromotionalCode = processInfo?.promotionalCode?.code
      return processPromotionalCode
    } else {
      if (codeLocal !== undefined) {
        return codeLocal.code
      }
    }
  }, [codeLocal, isAuthenticated, processInfo?.promotionalCode?.code])

  return (
    <React.Fragment>
      <ProcessToolbar
        handleBtnContinue={isAuthenticated ? handleBtnContinueLogged : handleBtnContinueNoLogged}
        hideButton={false}
        loading={isLoading || loadingContinue}
      />
      <Box
        pb={{ xs: 12, md: 0 }}
        component="form"
        onSubmit={handleComplete}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Paper
          sx={{
            width: { xs: '56vw', sm: '55vw', md: '62vw', lg: '75%', xl: '86v%' },
            p: 3,
            m: 2,
            pb: 6,
          }}
        >
          <Stack spacing={1.5}>
            <Grid container direction={{ xs: 'column', md: 'row' }} alignItems={'center'} gap={2}>
              <Grid item flexGrow={1}>
                <Typography fontWeight={700} color="textSecondary" variant="h6">
                  {'Carrito de compras'}
                </Typography>
                <Grid container alignItems={'center'} pt={0.5}>
                  <Icon sx={{ mr: 1 }} color="action">
                    event
                  </Icon>
                  <Typography variant="body2">
                    {`Fecha: ${format(new Date(), 'dd-MM-yyyy')}`}
                  </Typography>
                </Grid>
              </Grid>

              <Icon
                color="action"
                fontSize="large"
                sx={{ pt: { xs: 2, md: 0 }, display: { xs: 'none', md: 'block' } }}
              >
                receipt_long
              </Icon>

              <Grid item container flexGrow={1} alignItems="center">
                <InputBase
                  placeholder="Código de descuento"
                  sx={{
                    fontSize: '0.8em',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    mr: 1,
                    flexGrow: { xs: 1, md: 4 },
                    mb: { xs: 1.4, sm: 0 },
                    pl: '8px',
                  }}
                  value={isCode ? code : getCode ?? ''}
                  onChange={(e) => {
                    handleChangeCode(e.target.value)
                  }}
                />
                <LoadingButton
                  endIcon={<Icon>{promotionalCodeActive ? 'delete' : 'add'}</Icon>}
                  disabled={loadingValidate}
                  loading={loadingValidate}
                  sx={{
                    px: 4,
                    flexGrow: 1,
                    background: promotionalCodeActive
                      ? palette.secondary.main
                      : palette.primary.main,
                    color: '#fff',
                  }}
                  onClick={async () => {
                    if (promotionalCodeActive) {
                      await handleRemoveApplyCode()
                    } else {
                      await handleValidateApplyCode()
                    }
                  }}
                >
                  {`${promotionalCodeActive ? 'Quitar Código' : 'Aplicar Código'}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>

          <Grid item sx={{ pt: 2 }}>
            <Typography fontWeight={700} color="textSecondary" variant="body2">
              {'Detalles'}
            </Typography>

            {specialProductExpired && (
              <Alert severity="warning">
                Una de las promociones ya no esta disponible en el marketplace, pero puedes
                comunicarte con soporte para tener mas información o elimina la promoción para
                continuar con la compra!
              </Alert>
            )}
          </Grid>

          <ProductsDetails invoiceDetail={invoiceDetails} disableDelete enableAddAndRemoveButtons />

          {nonEmptyCart && (
            <Stack alignItems={{ xs: 'center', sm: 'flex-end' }} sx={{ pb: 3 }}>
              <CleanItemsBtn isAuth={isAuthenticated} processId={processInfo?.processId} />
            </Stack>
          )}

          <Stack spacing={1.5}>
            <Grid
              container
              alignItems={'center'}
              justifyContent={{ xs: 'center', sm: 'space-evenly', md: 'space-between' }}
            >
              <Typography fontWeight={700} color="textSecondary" variant="body2">
                {'Datos de facturación y pago'}
              </Typography>

              {existsCompanyInformationList && (
                <Box>
                  <Button
                    variant="text"
                    endIcon={<Icon sx={{ fontSize: 10 }}>person</Icon>}
                    onClick={(e) => {
                      handleOpenCompanyInformationMenu(e)
                    }}
                  >
                    {'Utilizar datos de otra cuenta'}
                  </Button>
                  {existsMoreThanOneCompanyInformation && (
                    <>
                      <IconButton
                        id="company-information-btn"
                        aria-controls={
                          openCompanyInformationMenu ? 'company-information-menu' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openCompanyInformationMenu ? 'true' : undefined}
                        onClick={handleOpenCompanyInformationMenu}
                      >
                        <Icon>arrow_drop_down</Icon>
                      </IconButton>
                      <Menu
                        id="company-information-menu"
                        MenuListProps={{
                          'aria-labelledby': 'company-information-btn',
                        }}
                        anchorEl={anchorEl}
                        open={openCompanyInformationMenu}
                        onClose={handleCloseCompanyInformationMenu}
                        TransitionComponent={Fade}
                      >
                        {companyInformationEnable.map((company, index) => {
                          const isSelected =
                            companyInformation.companyInformationId === company.companyInformationId
                          return (
                            <MenuItem
                              sx={{
                                backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                color: isSelected ? '#fff' : '#000',
                                '&:hover': {
                                  backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                  opacity: 0.8,
                                },
                              }}
                              key={company.companyId}
                              onClick={() => {
                                changeCompanyInformation(company)
                              }}
                            >
                              {company.identification + ' ' + company.name + ' ' + company.lastName}
                            </MenuItem>
                          )
                        })}
                      </Menu>
                    </>
                  )}
                </Box>
              )}

              {/* <FormControlLabel
                control={
                  <Checkbox
                    disabled={true}
                    checked={!shopToAnotherAccount}
                    onChange={async (e) => {
                      const shopToSameAccount = e.target.checked
                      handleChangeShopToAnotherAccount()
                      deleteEnableSteps(StepReferenceName.ACCOUNT)
                      if (!shopToSameAccount) {
                        deleteEnableSteps(StepReferenceName.PAYMENT)
                        if (isAuthenticated) {
                          await handleCleanAssignedCompanyToProcess(processInfo)
                        }
                      }
                    }}
                  />
                }
                label={
                  <Typography fontWeight={'bold'} fontSize={{ xs: 15, sm: 17 }}>
                    Esta compra es para mi
                  </Typography>
                }
              /> */}
            </Grid>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Grid item md={12}>
                <TextField
                  variant="filled"
                  label="Identificación"
                  fullWidth
                  inputProps={{ maxLength: 13 }}
                  autoFocus
                  required
                  value={companyInformation?.identification ?? ''}
                  onChange={(event) => {
                    const value = event.target.value

                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: value.replace(/[^0-9]/g, ''),
                      name: companyInformation.name,
                      middleName: companyInformation.middleName,
                      lastName: companyInformation.lastName,
                      mail: companyInformation.mail,
                      address: companyInformation.address,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      identification: '',
                    }))
                  }}
                />
                {fieldErrors.identification && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.identification}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="filled"
                  label="Correo electrónico"
                  fullWidth
                  required
                  value={companyInformation?.mail ?? ''}
                  onChange={(event) => {
                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: companyInformation.name,
                      middleName: companyInformation.middleName,
                      lastName: companyInformation.lastName,
                      mail: event.target.value,
                      address: companyInformation.address,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      mail: '',
                    }))
                  }}
                />
                {fieldErrors.mail && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.mail}
                  </Typography>
                )}
              </Grid>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Grid item md={12}>
                <TextField
                  variant="filled"
                  label="Primer Nombre"
                  fullWidth
                  required
                  value={companyInformation?.name ?? ''}
                  onChange={(event) => {
                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: event.target.value,
                      middleName: companyInformation.middleName,
                      lastName: companyInformation.lastName,
                      mail: companyInformation.mail,
                      address: companyInformation.address,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      name: '',
                    }))
                  }}
                />
                {fieldErrors.name && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.name}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="filled"
                  label="Segundo Nombre"
                  fullWidth
                  value={companyInformation?.middleName ?? ''}
                  onChange={(event) => {
                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: companyInformation.name,
                      middleName: event.target.value,
                      lastName: companyInformation.lastName,
                      mail: companyInformation.mail,
                      address: companyInformation.address,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      ' middleName': '',
                    }))
                  }}
                />
                {fieldErrors.middleName && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.middleName}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="filled"
                  label="Apellido"
                  fullWidth
                  required
                  value={companyInformation?.lastName ?? ''}
                  onChange={(event) => {
                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: companyInformation.name,
                      middleName: companyInformation.middleName,
                      lastName: event.target.value,
                      mail: companyInformation.mail,
                      address: companyInformation.address,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: '',
                    }))
                  }}
                />
                {fieldErrors.lastName && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.lastName}
                  </Typography>
                )}
              </Grid>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Grid md={12} item>
                <TextField
                  variant="filled"
                  label="Teléfono"
                  fullWidth
                  required
                  inputProps={{ maxLength: 10 }}
                  value={companyInformation?.phoneNumber ?? ''}
                  onChange={(event) => {
                    const value = event.target.value

                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: companyInformation.name,
                      middleName: companyInformation.middleName,
                      lastName: companyInformation.lastName,
                      mail: companyInformation.mail,
                      address: companyInformation.address,
                      phoneNumber: value.replace(/[^0-9]/g, ''),
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      phoneNumber: '',
                    }))
                  }}
                />
                {fieldErrors.phoneNumber && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.phoneNumber}
                  </Typography>
                )}
              </Grid>
              <Grid md={12} item>
                <TextField
                  variant="filled"
                  label="Dirección"
                  fullWidth
                  value={companyInformation?.address ?? ''}
                  onChange={(event) => {
                    const newCompanyInformation = {
                      companyId: companyInformation.companyId,
                      identification: companyInformation.identification,
                      name: companyInformation.name,
                      middleName: companyInformation.middleName,
                      lastName: companyInformation.lastName,
                      mail: companyInformation.mail,
                      address: event.target.value,
                      phoneNumber: companyInformation.phoneNumber,
                      enable: companyInformation.enable,
                      default: companyInformation.default,
                    } as ICompanyInformation

                    changeCompanyInformation(newCompanyInformation)
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      address: '',
                    }))
                  }}
                />
                {fieldErrors.address && (
                  <Typography variant="caption" color="error">
                    {fieldErrors.address}
                  </Typography>
                )}
              </Grid>
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Dialog
        open={openDialog.includes('saveInformation')}
        onClose={() => {
          handleCloseLoad('loadingContinue')
          handleCloseDialog('saveInformation')
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
          {'¿Desea guardar su información?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: 'center', fontWeight: 700, fontSize: 13 }}
          >
            Si deseas guardar la información podrás utilizarla para futuras compras
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: { xs: 2 },
          }}
        >
          <LoadingButton
            sx={{ width: '170px' }}
            disabled={noSavingLoading || savingLoading}
            onClick={() => {
              handleCloseLoad('loadingContinue')
              handleCloseDialog('saveInformation')
            }}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            loading={noSavingLoading}
            disabled={noSavingLoading || savingLoading}
            sx={{ width: '170px' }}
            onClick={handleBtnNotSave}
          >
            Continuar sin guardar
          </LoadingButton>
          <LoadingButton
            loading={savingLoading}
            disabled={savingLoading || noSavingLoading}
            sx={{ width: '170px' }}
            onClick={handleBtnSaveAndContinue}
            autoFocus
          >
            Guardar y continuar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog.includes('productsNeedsRuc')}
        onClose={() => {
          handleCloseDialog('productsNeedsRuc')
          handleCloseLoad('loadingContinue')
        }}
        maxWidth={isMobile ? 'sm' : 'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isIdentificationRucOrCi() && (
          <DialogTitle id="alert-dialog-title">
            <Alert severity="info" color="warning" sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}>
              <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                {'Algunos productos necesitan RUC'}
              </AlertTitle>
              {'Para poder utilizar estos productos debe cambiar su '}
              <strong>{'identificación'}</strong>
              {' a '}
              <strong>{'RUC.'}</strong>
            </Alert>
          </DialogTitle>
        )}
        <DialogContent
          sx={{
            p: 0,
            width: isMobile ? 'auto' : '48rem',
            overflow: isMobile ? 'scroll' : 'hidden',
          }}
        >
          <Box
            sx={
              isMobile
                ? { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                : { display: 'flex', gap: 1, justifyContent: 'space-around' }
            }
          >
            <Box py={2} width={isMobile ? '90%' : '45%'}>
              <Typography textAlign={'center'} fontWeight={'bold'}>
                {'Productos'}
              </Typography>

              {!isIdentificationRucOrCi() && (
                <Box pt={2}>
                  <Alert
                    severity="info"
                    color="warning"
                    sx={{ fontSize: isMobile ? '0.75rem' : 'auto' }}
                  >
                    <AlertTitle sx={{ fontSize: isMobile ? '0.85rem' : 'auto' }}>
                      {'Algunos productos necesitan RUC'}
                    </AlertTitle>
                    {'Para poder utilizar estos productos debe cambiar su '}
                    <strong>{'identificación'}</strong>
                    {' a '}
                    <strong>{'RUC.'}</strong>
                  </Alert>
                </Box>
              )}

              <Box
                pt={2}
                pl={'24px'}
                height={isMobile ? 'fit-content' : '16rem'}
                sx={{ overflow: isMobile ? 'hidden' : 'scroll' }}
              >
                {productsNeedsRuc.map((product) => (
                  <Box
                    py={1}
                    sx={{
                      py: isMobile ? '0' : '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <AvatarGroup spacing={'small'}>
                      <Avatar
                        src={getLogo(product.mainSolutionId)}
                        sx={{
                          width: { xs: 20, sm: 24, md: 28 },
                          height: { xs: 20, sm: 24, md: 28 },
                        }}
                      />
                    </AvatarGroup>
                    <Typography
                      fontWeight={600}
                      color="textSecondary"
                      variant="caption"
                      align="left"
                      fontSize={'0.75em'}
                    >
                      {product.name}
                    </Typography>
                  </Box>
                ))}
                {specialProductsNeedsRuc.map((specialProduct) => (
                  <Box
                    py={1}
                    sx={{
                      py: isMobile ? '0' : '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <AvatarGroup max={4} spacing={'small'}>
                      {specialProduct.specialProducts.map((product) => (
                        <Avatar
                          key={product.specialProductDetailId}
                          src={getLogo(product.productInformation.mainSolutionId)}
                          sx={{
                            width: { xs: 20, sm: 24, md: 28 },
                            height: { xs: 20, sm: 24, md: 28 },
                          }}
                        />
                      ))}
                    </AvatarGroup>
                    <Typography
                      fontWeight={600}
                      color="textSecondary"
                      variant="caption"
                      align="left"
                      pl={1}
                      fontSize={'0.75em'}
                    >
                      {specialProduct.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Divider orientation={isMobile ? 'horizontal' : 'vertical'} variant="middle" flexItem />

            <Box pt={2} width={isMobile ? '90%' : '45%'}>
              <Typography textAlign={'center'} fontWeight={'bold'}>
                Información
              </Typography>

              {isIdentificationRucOrCi() ? (
                <Box
                  py={1}
                  height={'18rem'}
                  sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    label={'Ruc'}
                    value={rucInformation.newRuc}
                    sx={{
                      width: '10em',
                      '& input.Mui-disabled': {
                        WebkitTextFillColor: MAIN_COLORS.primary.hover,
                      },
                      '& label.Mui-disabled': {
                        color: MAIN_COLORS.primary.hover,
                      },
                    }}
                    onChange={(e) =>
                      setRucInformation((prev) => ({ ...prev, newRuc: e.target.value }))
                    }
                    disabled={validateIdentificationCard(user.identification)}
                  />
                  {/* <TextField
                    label={'Razón Social'}
                    value={rucInformation.companyName}
                    sx={{
                      width: '10em',
                      '& input.Mui-disabled': {
                        WebkitTextFillColor: MAIN_COLORS.primary.hover,
                      },
                      '& label.Mui-disabled': {
                        color: MAIN_COLORS.primary.hover,
                      },
                    }}
                    onChange={(e) =>
                      setRucInformation((prev) => ({ ...prev, companyName: e.target.value }))
                    }
                    disabled={validateIdentificationCard(user.identification)}
                  /> */}
                </Box>
              ) : (
                <Box
                  pt={2}
                  pr={isMobile ? '0' : '24px'}
                  height={isMobile ? 'fit-content' : isIdentificationRucOrCi() ? '16rem' : '24rem'}
                  sx={{ overflow: isMobile ? 'hidden' : 'scroll' }}
                >
                  {option === ROUTES.signUp && (
                    <SignUpForm
                      fromModal={true}
                      getDataUser={getDataUser}
                      onClickSignUp={onClickSignUp}
                      handleChangeOption={handleChangeOption}
                    />
                  )}
                  {option === ROUTES.login && (
                    <LoginForm
                      fromModal={true}
                      dataUser={dataUser}
                      onClickLogin={onClickLogin}
                      handleChangeOption={handleChangeOption}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: MAIN_COLORS.alt.background,
              color: MAIN_COLORS.primary.main,
              border: `1px solid ${MAIN_COLORS.primary.main}`,
              '&:hover': {
                color: MAIN_COLORS.alt.background,
              },
            }}
            onClick={() => {
              handleCloseDialog('productsNeedsRuc')
              handleCloseLoad('loadingContinue')
            }}
          >
            {'Cancelar'}
          </Button>
          {isIdentificationRucOrCi() && (
            <Button
              onClick={() => handleOpenDialog('confirmProductsNeedsRuc')}
              autoFocus
              disabled={!validateIdentificationIsRuc(rucInformation?.newRuc ?? '')}
            >
              {'Si, cambiar a RUC'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog.includes('confirmProductsNeedsRuc')}
        onClose={() => handleCloseDialog('confirmProductsNeedsRuc')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'¿Está seguro de cambiar su identificación a RUC?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Una vez hecho el cambio ya no se puede revertir.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: MAIN_COLORS.alt.background,
              color: MAIN_COLORS.primary.main,
              border: `1px solid ${MAIN_COLORS.primary.main}`,
              '&:hover': {
                color: MAIN_COLORS.alt.background,
              },
            }}
            onClick={() => handleCloseDialog('confirmProductsNeedsRuc')}
          >
            {'Cancelar'}
          </Button>
          <LoadingButton
            loading={loadingConfirmProductsNeedsRuc}
            onClick={() => handleChangeIdentificationToRuc()}
            autoFocus
          >
            {'Si, cambiar a RUC'}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ModelOtherAccount
        open={openDialog.includes('login')}
        handleOpenDialog={() => {
          handleOpenDialog('selectProcess')
        }}
        loadingInitialize={loadingInitialize}
        initializeConfigureAfterLogin={initializeConfigureAfterLogin}
      />
    </React.Fragment>
  )
}
