export const MAIN_SOLUTIONS_PATH = '/main-solutions'
// api services paths
export const USERS_PATH = '/users'
export const RESEND_VERIFICATION_EMAIL = '/resend-verification-email'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const SIGN_UP_PATH = '/sign-up'
export const PROFILES_PATH = '/profiles'
export const PROFILE_ASSOCIATIONS_PATH = '/profile-associations'
export const STEPS_PATH = '/steps'
export const PROCESSES_PATH = '/processes'
export const PROCESS_PATH = '/process'
export const COPY_PROCESS_PATH = '/copy-process'
export const PRODUCTS_PATH = '/products'
export const PRODUCTS_TO_ACTIVATE_PATH = '/all-products-to-activate'
export const EXPIRED_PRODUCTS_PATH = '/expired-products'
export const SPECIAL_PRODUCTS_PATH = '/special-product'
export const TRANSACTIONS_PATH = '/transactions'
export const INVOICE_CONTRACTS_PATH = '/invoice-contracts'
export const INVOICE_EMMITED = '/invoice-emmit'
export const LICENSES_PATH = '/licenses'
export const COMPANIES_PATH = '/companies'
export const CLOSE_TUTORIAL_PATH = '/close-tutorial'
export const REFUND_REQUESTS_PATH = '/refund-requests'
export const REFUND_PATH = '/refund'
export const UPLOAD_PATH = '/upload'
export const IDENTIFICATION_TYPES_PATH = '/identification-types'
export const SESSION_PATH = '/session'
export const PROCESS_PRODUCT_PATH = '/process-product'
export const CLEAN_CART = '/clean-cart'
export const COMPANY_INFORMATION_PATH = '/company-information'
export const INVOICE_DETAILS = '/invoice-details'
export const DETAILS_BY_PRODUCTS_ID = '/details-by-products-id'
export const DEFAULT = '/default'
export const ENABLED = '/enabled'
export const DISABLED = '/disabled'
export const COMPANY_PATH = '/company'
export const ISSUER_INVOICE_INFORMATION = 'issuer-information'
export const PROMOTIONAL_CODE_PATH = '/promotional-codes'
export const DETAILS_PROMOTIONAL_CODE_ID_PATH = '/details-by-promotional-code'
export const VALIDATE_CODE_LOGGED = '/validate-code-logged'
export const VALIDATE_CODE = '/validate-code'
export const VALIDATE_RUC_PATH = '/validate-ruc'
export const REMOVE_CODE = '/remove-code'
export const CHANGE_COMPANY_PATH = '/change-company'
export const PERMANENTLY_DELETE_PATH = '/permanently-delete'
export const EMAIL_SERVICE_PATH = '/email-service'
export const NOTIFICATION_REFUND_REQUEST_PATH = '/notification-refund-request'
//to Forgot Password
export const FORGOT_PASSWORD_PATH = '/request-change-password'
export const CHANGE_PASSWORD_PATH = '/change-password'

export const DATAWEB_PATH = '/dataweb'
export const CHECKOUT_PATH = '/checkout'
export const CHECKOUT_DETAILS_PATH = '/checkout-details'
export const VALIDATE_EXISTING_ACCOUNT_PATH = '/validate-existing-account'
export const VALIDATE_EXISTING_IDENTIFICATION_PATH = '/validate-existing-identification'
export const VALIDATE_EXISTING_USERNAME_PATH = '/validate-existing-username'
export const VALIDATE_EXISTING_EMAIL_PATH = '/validate-existing-email'
export const VALIDATE_CREDENTIALS_PATH = '/validate-credentials'
export const VERIFY_IF_EXISTS_PATH = '/verify-if-exists'
export const VERIFY_IF_EXISTS_EMAIL_PATH = '/verify-if-exists-email'
export const CLOSE_PATH = '/close'
export const CANCEL_PATH = '/cancel'
export const TRANSACTION_LICENSE_PATH = '/transaction-license'
export const COMPLETE_PATH = '/complete'
export const REFERENCE_SPECIAL_PRODUCTS = 'ofertas'

export const TERMS_CONDITIONS_PATH = '/terms-conditions'

// url params
export const SEARCH_PARAM = '?'
export const AND_PARAM = '&'

export const COMPANY_ID_PARAM = 'companyId='
export const COMPANY_NAME_PARAM = 'companyName='
export const REFERENCE_PARAM = 'reference='
export const PROCESS_ID_PARAM = 'processId='
export const PRODUCTS_ID_PARAM = 'productsId='
export const SPECIAL_PRODUCTS_ID_PARAM = 'specialProductsId='
export const USER_IDENTIFICATION_PARAM = 'userIdentification='
export const IDENTIFICATION_PARAM = 'identification='
export const MAIN_SOLUTION_ID_PARAM = 'mainSolutionId='
export const EMAIL_PARAM = 'email='
export const USERNAME_PARAM = 'username='
export const INVOICE_CONTRACT_ID_PARAM = 'invoiceContractId='
