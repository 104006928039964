import {
  createInvoiceContractService,
  getInvoiceDetailsByProductsIdService,
  getInvoiceDetailsWithPromotionalCodeService,
  getInvoiceRefundService,
  updateInvoiceApplyCodeService,
  updateInvoiceContractService,
} from 'lib'
import React from 'react'
import { IInvoiceContract, IInvoiceDetails, IInvoiceRefund } from 'marketplace-common'
import { InvoiceContractWithProcessRequest } from 'types'

type Hook = {
  invoiceDetails: IInvoiceDetails
  handleInvoiceDetails: (
    productsId?: number[],
    specialProductsId?: number[],
    promotionalCodeId?: number
  ) => void
  handleSubmitInvoiceContract: (
    processId: number,
    invoiceContractId: number | null,
    codeValid?: boolean
  ) => Promise<IInvoiceContract>
  handleCreateInvoiceContract: (processId: number) => Promise<IInvoiceContract>
  loadingInvoice: boolean
  handleGetInvoiceRefund: (companyId: string) => Promise<IInvoiceRefund[]>
}

export const useInvoiceContract = (): Hook => {
  const [invoiceDetails, setInvoiceDetails] = React.useState<IInvoiceDetails>({} as IInvoiceDetails)
  const [loadingInvoice, setLoadingInvoice] = React.useState(false)

  const handleInvoiceDetails = async (
    productsId?: number[],
    specialProductsId?: number[],
    promotionalCodeId?: number
  ) => {
    try {
      setLoadingInvoice(true)
      if (promotionalCodeId !== undefined) {
        const response = await getInvoiceDetailsWithPromotionalCodeService(
          promotionalCodeId,
          productsId,
          specialProductsId
        )
        setInvoiceDetails(response)
      } else {
        const response = await getInvoiceDetailsByProductsIdService(productsId, specialProductsId)
        setInvoiceDetails(response)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingInvoice(false)
    }
  }

  const hanldleUpdateInvoiceApplyCode = async (processId: number, invoiceContractId: number) => {
    try {
      const resquest: InvoiceContractWithProcessRequest = {
        invoiceContractId,
        processId,
      }
      const response = await updateInvoiceApplyCodeService(invoiceContractId, resquest)
      return response
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const handleSubmitInvoiceContract = async (
    processId: number,
    invoiceContractId: number | null,
    codeValid?: boolean
  ) => {
    const existsInvoiceContractId: boolean = Boolean(invoiceContractId)
    if (existsInvoiceContractId && codeValid === false) {
      const response = await handleUpdateInvoiceContract(processId, invoiceContractId!)
      return response
    }

    if (existsInvoiceContractId && codeValid === true) {
      const response = await hanldleUpdateInvoiceApplyCode(processId, invoiceContractId!)
      return response
    }

    if (!existsInvoiceContractId && codeValid === true) {
      const responseUpdate = await handleCreateInvoiceContract(processId)
      if (responseUpdate !== undefined) {
        const response = await hanldleUpdateInvoiceApplyCode(
          processId,
          responseUpdate.invoiceContractId
        )
        return response
      }
    }
    const response = await handleCreateInvoiceContract(processId)
    return response
  }

  const handleCreateInvoiceContract = async (processId: number) => {
    const invoiceContractRequest = {
      processId,
    }
    try {
      const response = await createInvoiceContractService(invoiceContractRequest)
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUpdateInvoiceContract = async (processId: number, invoiceContractId: number) => {
    const invoiceContractRequest = {
      processId,
    }
    try {
      const response = await updateInvoiceContractService(invoiceContractId, invoiceContractRequest)
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleGetInvoiceRefund = async (companyId: string) => {
    try {
      const response = await getInvoiceRefundService(companyId)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  return {
    invoiceDetails,
    loadingInvoice,
    handleInvoiceDetails,
    handleGetInvoiceRefund,
    handleSubmitInvoiceContract,
    handleCreateInvoiceContract,
  }
}
