import { useAuth, useNavigate } from 'hooks'
import { PROCESS_ID_PARAM, ROUTES, SEARCH_PARAM, createProcessesService } from 'lib'
import { encryptParam } from 'utils'

interface Hook {
  handleContinueShop: () => void
}

export const useFuntionShop = (): Hook => {

    
  const { user, isAuthenticated } = useAuth()
  const { animatedNavigation } = useNavigate()

  const handleCreateProcess = async () => {
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    const response = await createProcessesService(process)
    return response
  }

  const handleShop = async () => {
    try {
      const process = await handleCreateProcess()
      if (process) {
        const processId = encryptParam(process?.processId.toString())
        const route = `${ROUTES.processes}/${'nuna'}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
        animatedNavigation(route)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShopNotLogged = async () => {
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(processNoLoggedRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleContinueShop = () => {
    isAuthenticated ? handleShop() : handleShopNotLogged()
  }

  return {
    handleContinueShop,
  }
}
