import { Box, Icon, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { AnimatedLink, StyledText } from 'components'
import { useAuth, useCart, useFuntionShop } from 'hooks'
import { ROUTES, verifyIfUserHasRefundRequestService } from 'lib'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import biosferasoftLogoIcon from '../../assets/img/logo-biosferasoft-icon.webp'
import biosferasoftLogoMain from '../../assets/img/logo-biosferasoft-main.webp'

type Props = {
  open: boolean
  handleDrawer: () => void
}

export const SidePanel = (props: Props) => {
  const { open: openDrawer, handleDrawer } = props

  const { user, logout, handleShowTutorial } = useAuth()
  const { nonEmptyCart } = useCart()
  const { pathname } = useLocation()
  const { cleanCart } = useCart()
  const { handleContinueShop } = useFuntionShop()
  const requestRouteActive = React.useMemo(() => pathname.includes(ROUTES.requests), [pathname])
  const contactsRouteActive = React.useMemo(() => pathname.includes(ROUTES.contacts), [pathname])
  const solutionsRouteActive = React.useMemo(() => pathname.includes(ROUTES.solutions), [pathname])
  const processRouteActive = React.useMemo(() => pathname.includes(ROUTES.processes), [pathname])
  const paymentsRouteActive = React.useMemo(() => pathname.includes(ROUTES.payments), [pathname])

  const [userHasRefundRequest, setUserHasRefundRequest] = React.useState<boolean>(false)

  const settingsRouteActive = React.useMemo(
    () => pathname.includes(ROUTES.configuration),
    [pathname]
  )

  const verifyIfUserHasRefundRequest = async () => {
    try {
      const result = await verifyIfUserHasRefundRequestService(user.identification)
      setUserHasRefundRequest(result)
    } catch (error) {}
  }

  React.useEffect(() => {
    verifyIfUserHasRefundRequest()
  }, [])

  return (
    <React.Fragment>
      <Stack>
        <Box
          width="100%"
          display="flex"
          flexDirection={openDrawer ? 'row' : 'column'}
          alignItems={openDrawer ? 'flex-start' : 'center'}
          justifyContent={openDrawer ? 'space-between' : 'center'}
        >
          <Box
            py={1}
            pl={openDrawer ? 4 : 0}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {openDrawer ? (
              <img
                src={biosferasoftLogoMain}
                alt={'Biosferasoft'}
                width="112"
                height="42"
                loading="lazy"
              />
            ) : (
              <img
                src={biosferasoftLogoIcon}
                alt={'Biosferasoft'}
                width="26"
                height="42"
                loading="lazy"
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: openDrawer ? 'row' : 'column-reverse',
            }}
          >
            {solutionsRouteActive && (
              <Tooltip
                title={'Mostrar tutorial'}
                placement={openDrawer ? 'bottom' : 'right'}
                onClick={() => {
                  handleShowTutorial(true)
                }}
              >
                <IconButton sx={{ margin: openDrawer ? '16px 8px 8px 12px' : 0 }}>
                  <Icon sx={{ color: '#000', fontSize: 25 }}>help</Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={openDrawer ? 'Minimizar menu' : 'Maximizar menu'}
              placement={openDrawer ? 'bottom' : 'right'}
            >
              <IconButton
                onClick={handleDrawer}
                sx={{ margin: openDrawer ? '16px 8px 8px 12px' : 0 }}
              >
                <Icon sx={{ color: '#000', fontSize: 25 }}>
                  {!openDrawer ? 'menu' : 'chevron_left_icon'}
                </Icon>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Stack>
      <Stack flexGrow={1}>
        {openDrawer && (
          <Box
            display={'flex'}
            py={3}
            pb={4.5}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <StyledText
              text={
                <Box display={'flex'} flexDirection="column" component={'p'}>
                  <Typography variant="caption" fontWeight={800} color="textSecondary">
                    {'Bienvenido'}
                  </Typography>
                  <Typography variant="caption" fontWeight={600}>
                    {user.name}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )}

        <MenuItem
          className="continueBuying-tour-step"
          sx={{ py: 2, px: 4 }}
          selected={settingsRouteActive}
          onClick={() => handleContinueShop()}
        >
          <Tooltip title={nonEmptyCart ? 'Continuar Compra' : 'Comprar'} placement="right">
            <Icon sx={{ mr: 2 }}>storefront</Icon>
          </Tooltip>
          {openDrawer && (
            <Typography variant="body2" fontWeight={settingsRouteActive ? 800 : undefined}>
              {nonEmptyCart ? 'Continuar Compra' : 'Comprar'}
            </Typography>
          )}
        </MenuItem>

        <NavLink
          to={`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`}
          end
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <MenuItem
            className="my-products-tour-step"
            sx={{ py: 2, px: 4 }}
            selected={solutionsRouteActive}
          >
            <Tooltip title={'Mis Productos'} placement="right">
              <Icon sx={{ mr: 2 }}>apps</Icon>
            </Tooltip>
            {openDrawer && (
              <Typography variant="body2" fontWeight={solutionsRouteActive ? 800 : undefined}>
                {'Mis Productos'}
              </Typography>
            )}
          </MenuItem>
        </NavLink>

        <NavLink
          to={`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.processes}`}
          end
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <MenuItem
            className="my-shopping-car-tour-step"
            sx={{ py: 2, px: 4 }}
            selected={processRouteActive}
          >
            <Tooltip title={'Mi Carrito'} placement="right">
              <Icon sx={{ mr: 2 }}>auto_awesome_motion</Icon>
            </Tooltip>
            {openDrawer && (
              <Typography variant="body2" fontWeight={processRouteActive ? 800 : undefined}>
                {'Mi Carrito'}
              </Typography>
            )}
          </MenuItem>
        </NavLink>

        <NavLink
          to={`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.payments}`}
          end
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <MenuItem
            className="my-payments-tour-step"
            sx={{ py: 2, px: 4 }}
            selected={paymentsRouteActive}
          >
            <Tooltip title={'Mis Pagos'} placement="right">
              <Icon sx={{ mr: 2 }}>payments</Icon>
            </Tooltip>
            {openDrawer && (
              <Typography variant="body2" fontWeight={paymentsRouteActive ? 800 : undefined}>
                {'Mis Pagos'}
              </Typography>
            )}
          </MenuItem>
        </NavLink>

        {userHasRefundRequest && (
          <NavLink
            to={`/${ROUTES.user}/${ROUTES.requests}/${ROUTES.refunds}`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <MenuItem sx={{ py: 2, px: 4 }} selected={requestRouteActive}>
              <Tooltip title={'Solicitudes'} placement="right">
                <Icon sx={{ mr: 2 }}>mail_outline</Icon>
              </Tooltip>
              {openDrawer && (
                <Typography variant="body2" fontWeight={requestRouteActive ? 800 : undefined}>
                  {'Solicitudes'}
                </Typography>
              )}
            </MenuItem>
          </NavLink>
        )}
      </Stack>

      <NavLink
        to={`/${ROUTES.user}/${ROUTES.configuration}/${ROUTES.account}`}
        end
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <MenuItem
          className="my-settings-tour-step"
          sx={{ py: 2, px: 4 }}
          selected={settingsRouteActive}
        >
          <Tooltip title={'Mi Configuración'} placement="right">
            <Icon sx={{ mr: 2 }}>settingsIcon</Icon>
          </Tooltip>
          {openDrawer && (
            <Typography variant="body2" fontWeight={settingsRouteActive ? 800 : undefined}>
              {'Mi Configuración'}
            </Typography>
          )}
        </MenuItem>
      </NavLink>

      <Box style={{ color: 'inherit', textDecoration: 'none' }}>
        <MenuItem
          onClick={() => {
            cleanCart()
            logout()
          }}
          sx={{ py: 2, px: 4 }}
        >
          <Tooltip title={'Cerrar sesión'} placement="right">
            <Icon sx={{ mr: 2 }}>logoutIcon</Icon>
          </Tooltip>
          {openDrawer && <Typography variant="body2">{'Cerrar sesión'}</Typography>}
        </MenuItem>
      </Box>

      <NavLink
        to={`/${ROUTES.user}/${ROUTES.contacts}/${ROUTES.requestRefund}`}
        end
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <MenuItem sx={{ py: 2, px: 4 }} selected={contactsRouteActive}>
          <Tooltip title={'Ayuda - Contactos'} placement="right">
            <Icon sx={{ mr: 2 }}>contacts</Icon>
          </Tooltip>
          {openDrawer && (
            <Typography variant="body2" fontWeight={contactsRouteActive ? 800 : undefined}>
              {'Ayuda - Contactos'}
            </Typography>
          )}
        </MenuItem>
      </NavLink>

      {openDrawer && (
        <>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.8,
            }}
          >
            <AnimatedLink to={`${ROUTES.feedback}/${ROUTES.policy}`} type="circle">
              <Typography variant="caption" sx={{ textDecoration: 'underline' }}>
                {'Políticas de privacidad'}
              </Typography>
            </AnimatedLink>

            <AnimatedLink to={`${ROUTES.feedback}/${ROUTES.terms}`} type="circle">
              <Typography variant="caption" sx={{ textDecoration: 'underline' }}>
                {'Terminos y condiciones'}
              </Typography>
            </AnimatedLink>
          </Box>
          <Box mx={1} my={2}>
            <StyledText
              text={
                <Box display={'flex'} flexDirection="column" component={'p'}>
                  <Typography variant="caption" fontWeight={500} color="textSecondary">
                    {`Marketplace ${new Date().getFullYear()}`}
                  </Typography>
                  <Typography variant="caption" fontWeight={600}>
                    {'® Todos los derechos reservados'}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </>
      )}
    </React.Fragment>
  )
}
