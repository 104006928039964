import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { CardCompanyInformation } from 'components'
import { motion } from 'framer-motion'
import { useAuth, useFeedback, useForgotPassword } from 'hooks'
import {
  createCompanyInformationDefaultService,
  createCompanyInformationEnabledService,
  getAllCompanyInformationEnableService,
  getCompanyInformationByIdService,
  setDefaultCompanyInformationService,
  updateCompanyInformationService,
  validateExistingEmailService,
} from 'lib'
import { ICompanyInformation } from 'marketplace-common'
import React from 'react'
import { MAIN_COLORS } from 'styles'
import { IUserInformation } from 'types'
import {
  fieldValidationDirection,
  fieldValidationEmail,
  fieldValidationIdentification,
  fieldValidationOnlyLetters,
  fieldValidationPhone,
} from 'utils'
import DialogConfirmNewData from './DialogConfirmNewData'

interface IValidateErrors {
  name: string
  middleName: string
  lastName: string
  mail: string
  identification: string
  phoneNumber: string
  address: string
}
const validateErrorsDefault = {
  name: '',
  middleName: '',
  lastName: '',
  mail: '',
  identification: '',
  phoneNumber: '',
  address: '',
}

export const AccountConfiguration = () => {
  const { user, updateUserInfo } = useAuth()
  const { showMessage } = useFeedback()
  const { sendForgotPasswordRequest, switchLoading, loading, changeEmail } = useForgotPassword()
  const [userInformation, setUserInformation] = React.useState({} as IUserInformation)
  const [updating, setUpdating] = React.useState<boolean>(false)
  const [updatingDefault, setUpdatingDefault] = React.useState<boolean>(false)
  const [updatingPayment, setUpdatingPayment] = React.useState<boolean>(false)
  const [validEmail, setValidEmail] = React.useState<boolean>(true)
  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [companyInformationList, setCompanyInformationList] = React.useState<ICompanyInformation[]>(
    []
  )
  const [edit, setEdit] = React.useState<boolean>(false)
  const [companyInformation, setCompanyInformation] = React.useState({} as ICompanyInformation)
  const [open, setOpen] = React.useState<boolean>(false)
  const [fieldErrors, setFieldErrors] = React.useState<IValidateErrors>(validateErrorsDefault)
  const [selectedCompanyInformationId, setSelectedCompanyInformationId] = React.useState<
    number | undefined
  >(undefined)

  const onCancel = () => setOpen(false)

  const handleChange = async (key: keyof IUserInformation, value: string) => {
    setUserInformation((current) => ({
      ...current,
      [key]: value,
    }))
    if (key === 'email') {
      await validateEmail()
    }
    if (key === 'username') {
      showMessage('El nombre de usuario no puede ser modificado', 'warning')
    }
  }

  const handleSelectCompanyInformation = (companyInformationId: number) => {
    setSelectedCompanyInformationId(companyInformationId)
  }

  const validateEmail = async () => {
    if (userInformation.email === undefined) {
      return
    }
    if (user.email === userInformation.email) {
      setValidEmail(true)
      return
    }
    try {
      //TODO: pendiente de revisar y hacer uso para validar siya existe
      await validateExistingEmailService(userInformation.email) //Valida en Security

      if (!fieldValidationEmail(userInformation.email.trim())) {
        setValidEmail(false)
      } else {
        setValidEmail(true)
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const handleSubmitUser = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setUpdating(true)
      await validateEmail()
      await updateUserInfo(userInformation)
      showMessage('Cuenta actualizada', 'success')
    } catch (error: any) {
      showMessage(error.message ?? 'Error actualizando cuenta', 'error')
    } finally {
      setUpdating(false)
      onCancel()
    }
  }

  const validateFields = () => {
    const errors: Partial<IValidateErrors> = {}

    if (companyInformation.name.trim() === '' || companyInformation.name === undefined) {
      errors.name = 'El campo Primer Nombre es requerido'
    } else if (!fieldValidationOnlyLetters(companyInformation.name.trim())) {
      errors.name = 'No debe contener números ni caracteres especiales'
    }

    if (
      companyInformation.middleName.length > 0 &&
      !fieldValidationOnlyLetters(companyInformation.middleName.trim())
    ) {
      errors.middleName = 'No debe contener números ni caracteres especiales'
    }

    if (companyInformation.lastName.trim() === '' || companyInformation.lastName === undefined) {
      errors.lastName = 'El campo Apellido es requerido'
    } else if (!fieldValidationOnlyLetters(companyInformation.lastName.trim())) {
      errors.lastName = 'No debe contener números ni caracteres especiales'
    }

    if (companyInformation.mail.trim() === '' || companyInformation.mail === undefined) {
      errors.mail = 'El campo Correo es requerido'
    } else if (!fieldValidationEmail(companyInformation.mail.trim())) {
      errors.mail = 'El correo ingresado no es válido'
    }

    if (
      companyInformation.identification.trim() === '' ||
      companyInformation.identification === undefined
    ) {
      errors.identification = 'El campo Identificación es requerido'
    } else if (!fieldValidationIdentification(companyInformation.identification.trim())) {
      errors.identification = 'La identificación ingresada no es válida'
    }

    if (
      companyInformation.phoneNumber.trim() === '' ||
      companyInformation.phoneNumber === undefined
    ) {
      errors.phoneNumber = 'El campo Número Celular es requerido'
    } else if (!fieldValidationPhone(companyInformation.phoneNumber.trim())) {
      errors.phoneNumber = 'No tiene el formato correcto'
    }

    if (
      companyInformation?.address?.trim() !== undefined &&
      fieldValidationDirection(companyInformation?.address?.trim())
    ) {
      errors.address = 'La dirección no debe contener [{}[]!¡¿?%$=*+]'
    }

    setFieldErrors(errors as IValidateErrors)
    // Devolver true si no hay errores, o false si hay errores
    return Object.keys(errors).length === 0
  }

  const handleSubmitUserPayment = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      if (validateFields()) {
        setUpdatingPayment(true)
        if (edit) {
          const response = await updateCompanyInformationService(
            companyInformation.companyInformationId,
            companyInformation
          )
          setCompanyInformation(response)
        } else {
          if (companyInformationList.length === 0) {
            await createCompanyInformationDefaultService(companyInformation)
          } else {
            await createCompanyInformationEnabledService(companyInformation)
          }
          resetCompanyInformation()
        }
        handleCompanyInformationList(user.identification)
        showMessage(edit ? 'Perfil Actualizado' : 'Perfil Creado', 'success')
      }
    } catch (error: any) {
      showMessage(error.message, 'error')
    } finally {
      setUpdatingPayment(false)
    }
  }
  const resetCompanyInformation = () => {
    const emptyCompanyInformation = {
      companyId: '',
      identification: '',
      name: '',
      middleName: '',
      lastName: '',
      mail: '',
      address: '',
      phoneNumber: '',
      companyInformationId: 0,
      enable: false,
      default: false,
    }
    setCompanyInformation(emptyCompanyInformation)
    setFieldErrors(validateErrorsDefault)
  }

  const handleSetDefault = async (companyInformationId: number) => {
    try {
      setUpdatingDefault(true)
      await setDefaultCompanyInformationService(companyInformationId)
      await handleCompanyInformationList(user.identification)
      await loadCompanyInformation(companyInformationId)
      showMessage('Perfil establecido como Predeterminado', 'success')
    } catch (error: any) {
      showMessage('Error al intentar establecer el perfil como predeterminado', 'error')
    } finally {
      setUpdatingDefault(false)
    }
  }
  const handleChangePayment = (key: keyof ICompanyInformation, value: string) => {
    setCompanyInformation((current) => ({
      ...current,
      [key]: value,
    }))
    setCompanyInformation((current) => ({
      ...current,
      companyId: user.identification,
    }))
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [key]: '',
    }))
  }

  const handleSendForgotPasswordRequest = React.useCallback(async () => {
    try {
      await sendForgotPasswordRequest()
      showMessage('El correo de recuperación fue enviado ', 'success')
    } catch (error) {
      showMessage('Verifica que tu correo electrónico sea correcto', 'error')
    } finally {
      switchLoading()
    }
  }, [sendForgotPasswordRequest])

  const handleCompanyInformationList = async (companyId: string) => {
    try {
      const response = await getAllCompanyInformationEnableService(companyId)
      setCompanyInformationList(response)
    } catch (error) {
      console.log('error', error)
    }
  }
  const loadCompanyInformation = async (companyInformationId: number) => {
    const companyInformation = await getCompanyInformationByIdService(companyInformationId)
    setCompanyInformation(companyInformation)
    setEdit(true)
  }

  React.useEffect(() => {
    if (user.userId) {
      setUserInformation({
        email: user.email,
        name: user.name,
        phone: user.telephone,
        username: user.userName,
      })
      changeEmail(user.email)
    }
    if (user.identification) {
      handleCompanyInformationList(user.identification)
    }
  }, [user])

  React.useEffect(() => {
    validateEmail()
  }, [])

  return (
    <Box
      flexGrow={1}
      sx={{
        py: 6,
        px: 3,
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Paper
        component="form"
        onSubmit={handleSubmitUserPayment}
        elevation={0}
        sx={{ width: '100%', height: 'fit-content', pb: 2 }}
      >
        <Box px={4} py={2}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 700, fontSize: { xs: 17, sm: 20 } }}
            color="textSecondary"
          >
            {'Información de Pago'}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row-reverse',
            gap: 2,
            pt: 2,
            width: '100%',
            px: 4,
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Paper
            component={motion.div}
            whileHover={{ scale: 1.1 }}
            sx={{ background: 'transparent', borderRadius: 3, cursor: 'pointer' }}
            onClick={() => {
              setEdit(false)
              setShowForm(true)
              setSelectedCompanyInformationId(undefined)
              resetCompanyInformation()
            }}
          >
            <Box
              sx={{
                background: MAIN_COLORS.primary.main,
                minWidth: '135px',
                width: '135px',
                padding: 1,
                height: '150px',
                borderRadius: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <IconButton
                sx={{
                  borderRadius: '50%',
                  width: '60px',
                  height: '60px',
                  color: 'white',
                }}
                onClick={() => setShowForm(!showForm)}
              >
                <Avatar sx={{ fontSize: 70, width: '100%', height: '100%' }}>+</Avatar>
              </IconButton>

              <Typography
                variant="caption"
                textAlign={'center'}
                sx={{ lineHeight: 1.3, fontWeight: 600, fontSize: 12, color: 'white' }}
              >
                {'Agregar información de pago'}
              </Typography>
            </Box>
          </Paper>

          {companyInformationList &&
            companyInformationList.map((companyInformation) => {
              const isSelected =
                companyInformation.companyInformationId === selectedCompanyInformationId
              return (
                <CardCompanyInformation
                  key={companyInformation.companyInformationId}
                  companyInformation={companyInformation}
                  handleCompanyInformationList={handleCompanyInformationList}
                  setShowForm={setShowForm}
                  loadCompanyInformation={loadCompanyInformation}
                  resetCompanyInformation={resetCompanyInformation}
                  setEdit={setEdit}
                  isSelected={isSelected}
                  handleSelectCompanyInformation={handleSelectCompanyInformation}
                />
              )
            })}
        </Box>

        {showForm && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, py: 3, px: 4 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Primer Nombre *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.name}
                    onChange={(e) => handleChangePayment('name', e.target.value)}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.name && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.name}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Segundo Nombre'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.middleName}
                    onChange={(e) => handleChangePayment('middleName', e.target.value)}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.middleName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.middleName}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Apellido *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.lastName}
                    onChange={(e) => handleChangePayment('lastName', e.target.value)}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.lastName && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.lastName}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Correo *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    type="email"
                    value={companyInformation.mail}
                    onChange={(e) => handleChangePayment('mail', e.target.value)}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.mail && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.mail}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Identificación *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.identification}
                    onChange={(e) => {
                      const value = e.target.value
                      handleChangePayment('identification', value.replace(/[^0-9]/g, ''))
                    }}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.identification && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.identification}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Número de celular *'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value
                      handleChangePayment('phoneNumber', value.replace(/[^0-9]/g, ''))
                    }}
                    disabled={updatingPayment}
                    inputProps={{ maxLength: 10 }}
                  />
                  {fieldErrors.phoneNumber && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.phoneNumber}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
                <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                  {'Dirección'}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={companyInformation.address}
                    onChange={(e) => handleChangePayment('address', e.target.value)}
                    disabled={updatingPayment}
                  />
                  {fieldErrors.address && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ position: 'absolute', bottom: -20, left: 0 }}
                    >
                      {fieldErrors.address}
                    </Typography>
                  )}
                </Box>
              </Box>
              {(companyInformationList.length > 0 || edit) && (
                <Box
                  sx={{
                    width: { xs: '100%', md: 'calc(50% - 8px)' },
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {!companyInformation.default && (
                    <>
                      <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                        {'Establecer Por Defecto'}
                      </Typography>
                      {updatingDefault && <CircularProgress size={20} />}
                      {!updatingDefault && (
                        <Switch
                          checked={companyInformation.default}
                          onChange={() => handleSetDefault(companyInformation.companyInformationId)}
                          disabled={updatingDefault}
                        />
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                pt: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: { xs: 'center', sm: 'flex-end' },
              }}
            >
              <LoadingButton
                sx={{
                  px: { xs: 3, sm: 2 },
                  py: { xs: 1.5 },
                  gap: { xs: 1 },
                  fontSize: { xs: 15 },
                  fontWeight: 600,
                  width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33% - 8px)' },
                }}
                type="submit"
                loading={updatingPayment}
              >
                {edit ? 'Editar Información de Pago' : 'Crear Información de Pago'}
                <Icon>credit_card</Icon>
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Paper>

      <Paper
        component="form"
        onSubmit={handleSubmitUser}
        elevation={0}
        sx={{ width: '100%', height: 'fit-content', pb: 2 }}
      >
        <Box sx={{ px: 4, py: 2, textAlign: { xs: 'center', sm: 'left' } }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 700, fontSize: { xs: 17, sm: 20 } }}
            color="textSecondary"
          >
            {'Información de usuario'}
          </Typography>
        </Box>
        <Divider />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 3, px: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'flex-start',
              alignItems: { xs: 'flex-start', sm: 'center' },
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33% - 8px)' },
                height: 88,
              }}
            >
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Identificación'}
              </Typography>
              <TextField sx={{ width: '100%' }} value={user.identification} disabled={true} />
            </Box>

            <Box
              sx={{
                width: { xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(33% - 8px)' },
                height: 88,
              }}
            >
              <Typography variant="caption" fontWeight={500} sx={{ opacity: 0.65 }}>
                {'Correo electrónico'}
              </Typography>
              <TextField
                sx={{ width: '100%' }}
                value={userInformation.email}
                onChange={(e) => handleChange('email', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon color={validEmail && userInformation.email ? 'success' : 'error'}>
                        {validEmail ? 'check_circle_outline' : 'cancel'}
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                disabled={true}
                error={!validEmail}
                helperText={!validEmail ? 'Correo electrónico ya utilizado o incorrecto' : ''}
                FormHelperTextProps={{ sx: { color: 'red' } }}
                onBlur={async () => await validateEmail()}
              />
            </Box>
          </Box>
          <Box
            sx={{
              pt: 1.5,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 2, sm: 1, md: 0 },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: { xs: 'center' },
            }}
          >
            <Button
              variant="text"
              disableElevation
              disableRipple
              disableFocusRipple
              sx={{
                fontSize: { xs: 12, sm: 14 },
                fontWeight: 700,
                backgroundColor: 'transparent',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&:active': {
                  backgroundColor: 'transparent',
                },
              }}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault()
                handleSendForgotPasswordRequest()
              }}
            >
              <Typography variant="body1" sx={{ textDecoration: 'underline' }}>
                ¿Olvidaste tu contraseña?
              </Typography>
            </Button>
          </Box>
        </Box>
      </Paper>
      <DialogConfirmNewData
        open={open}
        onAccept={handleSubmitUser}
        onCancel={onCancel}
        loading={updating}
      />
    </Box>
  )
}
