import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth, useNavigate } from 'hooks'
import { LANDINGS_PAGES, PROCESS_ID_PARAM, ROUTES, SEARCH_PARAM, createProcessesService } from 'lib'
import { ISolution } from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { SOLUTION_COLORS } from 'styles'
import { encryptParam } from 'utils'

type Props = {
  solution: ISolution
  userHaveSolution: boolean
  onSelect?: (solution: ISolution) => void
}

/**
 *
 *
 * @see /types/entities/solution.ts for more info
 *
 * @param props solution: Object with all the Solution information check entities/solution.ts file for more information
 * selected: if the solution is already in the cart
 * onSelect: callback triggered when os user select a solution card
 * @returns Card with solution info
 */

export const SolutionCard = (props: Props) => {
  const { solution, userHaveSolution } = props
  const { user, isAuthenticated } = useAuth()
  const { animatedNavigation } = useNavigate()

  const handleShop = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const shortName = solution.name[0] + solution.name[1]
    ReactPixel.track('viewContentSolutions' + shortName, {
      userId: Boolean(user.userId) ? user.userId : null,
      mainSolution: solution.mainSolutionId,
      date: new Date(),
    })

    event.stopPropagation()
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    try {
      const response = await createProcessesService(process)
      const processId = encryptParam(response.processId)

      const encryptRoute = `${ROUTES.processes}/${solution.reference}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
      animatedNavigation(encryptRoute, 'circle')
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleShopNotLogged = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const shortName = solution.name[0] + solution.name[1]
    ReactPixel.track('viewContentSolutions' + shortName, {
      mainSolution: solution.mainSolutionId,
      date: new Date(),
    })

    event.stopPropagation()
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/${solution.reference}`
      animatedNavigation(processNoLoggedRoute, 'circle')
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleRedirectLanding = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mainSolutionId: number
  ) => {
    event.stopPropagation()

    const shortName = solution.name[0] + solution.name[1]
    ReactPixel.track('viewContentLanding' + shortName, {
      userId: Boolean(user.userId) ? user.userId : null,
      mainSolution: solution.mainSolutionId,
      date: new Date(),
    })

    const landing = LANDINGS_PAGES[mainSolutionId]
    if (landing !== '') {
      window.open(landing, '_blank')
    }
  }

  return (
    <Card
      sx={{
        width: 220,
        height: 220,
        cursor: 'pointer',
        opacity: 1,
        position: 'relative',
        overflow: 'visible',
      }}
      onClick={(event) => {
        if (solution.enabled) {
          isAuthenticated ? handleShop(event) : handleShopNotLogged(event)
        }
      }}
      component={motion.div}
      whileHover={{ scale: 1.1 }}
    >
      <CardMedia>
        <Box
          height={'65%'}
          bgcolor={SOLUTION_COLORS[solution.reference].primary.main ?? 'primary.main'}
          sx={{
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {solution.logo && (
            <Avatar
              src={solution.logo}
              sx={{
                width: 'calc(202px * 0.375)',
                height: 'calc(202px * 0.375)',
                padding: '25px',
              }}
            />
          )}
        </Box>
      </CardMedia>
      <CardContent sx={{ p: '6px' }}>
        <Box
          height={'35%'}
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          alignItems={'right'}
        >
          <React.Fragment>
            <Typography
              align="center"
              sx={{ color: SOLUTION_COLORS[solution.reference].primary.main ?? 'primary.main' }}
              fontWeight={700}
            >
              {solution.name}
            </Typography>
            <Typography align="center" variant="caption" color="textSecondary" fontWeight={600}>
              {solution.description}
            </Typography>
          </React.Fragment>
        </Box>
      </CardContent>
      {solution.enabled ? (
        <Box sx={{ display: 'flex', paddingX: 1, gap: 1 }}>
          <Button
            sx={{ flex: 1, opacity: '85%', fontSize: 9.2, paddingX: 1, margin: 0 }}
            variant="contained"
            size="small"
            onClick={(event) => {
              handleRedirectLanding(event, solution.mainSolutionId)
            }}
          >
            Más información
          </Button>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            size="small"
            onClick={() => (isAuthenticated ? handleShop : handleShopNotLogged)}
          >
            Comprar
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            color: SOLUTION_COLORS[solution.reference].primary.main ?? 'primary.main',
          }}
        >
          <Typography fontWeight={700} variant="button" display="block">
            Próximamente....
          </Typography>
        </Box>
      )}

      <AnimatePresence>
        {userHaveSolution && (
          <Tooltip title="Producto Adquirido" placement="top">
            <Box
              sx={{
                position: 'absolute',
                top: -9,
                right: -9,
                width: '25px',
                height: '25px',
                backgroundColor: 'white',
                borderRadius: '100%',
              }}
            >
              <Icon
                sx={{
                  position: 'absolute',
                  top: -7,
                  right: -7,
                  borderRadius: '100%',
                }}
                fontSize="large"
                color="success"
                component={motion.span}
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
              >
                check_circle
              </Icon>
            </Box>
          </Tooltip>
        )}
      </AnimatePresence>
    </Card>
  )
}
