import { Avatar, Icon, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { motion } from 'framer-motion'
import { useFeedback } from 'hooks'
import { deleteCompanyInformationService } from 'lib'
import { MAIN_COLORS } from 'styles'
import { ICompanyInformation } from 'marketplace-common'

interface Props {
  companyInformation: ICompanyInformation
  handleCompanyInformationList: (companyId: string) => void
  setShowForm: (showForm: boolean) => void
  loadCompanyInformation: (companyInformationI: number) => void
  resetCompanyInformation: () => void
  setEdit: (edit: boolean) => void
  handleSelectCompanyInformation: (companyInformationId: number) => void
  isSelected: boolean
}

export const CardCompanyInformation = (props: Props) => {
  const {
    companyInformation,
    handleCompanyInformationList,
    setShowForm,
    loadCompanyInformation,
    setEdit,
    resetCompanyInformation,
    handleSelectCompanyInformation,
    isSelected,
  } = props

  const { showMessage } = useFeedback()
  const handleDeleteInformation = async (companyInformation: ICompanyInformation) => {
    await deleteCompanyInformationService(companyInformation.companyInformationId)
    showMessage('Perfil Eliminado', 'success')
    setEdit(false)
    resetCompanyInformation()
    handleCompanyInformationList(companyInformation.companyId)
  }

  const handleSeleted = () => {
    handleSelectCompanyInformation(companyInformation.companyInformationId)
  }

  return (
    <Paper
      component={motion.div}
      whileHover={{ scale: 1.1 }}
      sx={{ background: 'transparent', borderRadius: 3, cursor: 'pointer' }}
      onClick={() => {
        setShowForm(true)
        handleSeleted()
        loadCompanyInformation(companyInformation.companyInformationId)
      }}
    >
      <Box
        sx={{
          background: isSelected ? MAIN_COLORS.secondary.selected : MAIN_COLORS.alt.background,
          minWidth: '135px',
          Width: '135px',
          height: '150px',
          borderRadius: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: companyInformation.default ? 'space-between' : 'flex-end',
          }}
        >
          {companyInformation.default && (
            <Tooltip title="Información de Pago Predeterminada" arrow>
              <IconButton
                sx={{
                  color: '#f0dc02',
                }}
              >
                <Icon>bookmark</Icon>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Eliminar Perfil" arrow>
            <IconButton
              sx={{
                color: '#dd1a22',
              }}
              onClick={() => handleDeleteInformation(companyInformation)}
            >
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '135px',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ background: MAIN_COLORS.primary.main, py: 3.5, px: 3.5 }}>
            {companyInformation.name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              lineHeight: 1.3,
              fontWeight: 700,
              fontSize: 14,
              color: MAIN_COLORS.alt.text,
            }}
          >
            {`${companyInformation.name} ${companyInformation.lastName}`}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: MAIN_COLORS.alt.text,
              pb: 2,
            }}
          >
            {companyInformation.companyId}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}
