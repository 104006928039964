import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useCart, useGetSolutions } from 'hooks'
import { IInvoiceDetails, IInvoiceItem } from 'marketplace-common'
import React from 'react'
import { ProductDetail } from './ProductDetail'
import { SpecialProductDetail } from './SpecialProductDetail'

type Props = {
  invoiceDetail: IInvoiceDetails
  disableDelete?: boolean
  enableAddAndRemoveButtons?: boolean
  hideWarningIcon?: boolean
}

export const ProductsDetails = (props: Props) => {
  const { invoiceDetail, disableDelete, enableAddAndRemoveButtons, hideWarningIcon } = props
  const { getLogo } = useGetSolutions()
  const { specialProductsIdFromLocalStorage, specialProductsId } = useCart()

  const hasSpecialProducts = React.useMemo(() => {
    if (specialProductsIdFromLocalStorage === undefined && specialProductsId === undefined) {
      return false
    }
    return (
      (specialProductsIdFromLocalStorage && specialProductsIdFromLocalStorage.length > 0) ||
      (specialProductsId && specialProductsId.length > 0)
    )
  }, [specialProductsIdFromLocalStorage, specialProductsId])

  const products = React.useMemo(() => {
    const { products } = invoiceDetail
    if (!Boolean(products)) {
      return [] as IInvoiceItem[]
    }
    return products
  }, [invoiceDetail])

  const renderDiscount = invoiceDetail.discount?.replace('$', '') !== '0' || ''

  const existProducts = React.useMemo(() => {
    return products.length !== 0
  }, [products])

  const existSpecialProduct = React.useMemo(() => {
    return invoiceDetail.specialProducts !== undefined && invoiceDetail.specialProducts.length !== 0
  }, [invoiceDetail.specialProducts])

  return (
    <Stack pt={1} spacing={1}>
      <TableContainer
        sx={{
          maxHeight: '200px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#fff',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#000',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#000',
          },
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'left' }}>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  Producto
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: enableAddAndRemoveButtons ? 'center' : 'right' }}>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  Cantidad
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  sx={{
                    fontSize: '0.85em',
                    fontWeight: 700,
                  }}
                >
                  Precio
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  sx={{
                    fontSize: '0.8em',
                    fontWeight: 700,
                  }}
                >
                  Descuento
                </Typography>
              </TableCell>
              {(!disableDelete || hasSpecialProducts) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {existProducts || existSpecialProduct ? (
              <>
                {invoiceDetail.specialProducts
                  ?.sort((a, b) => a.specialProductId - b.specialProductId)
                  .map((specialProductItem, index) => (
                    <SpecialProductDetail
                      key={specialProductItem.specialProductId}
                      getLogo={getLogo}
                      specialProductItem={specialProductItem}
                      disableDelete={disableDelete}
                      showButtons={enableAddAndRemoveButtons}
                      hasSpecialProducts={hasSpecialProducts}
                      hideWarningIcon={hideWarningIcon}
                    />
                  ))}

                {products
                  .sort((a, b) => a.productId - b.productId)
                  .map((product) => (
                    <ProductDetail
                      key={product.productId + '-product'}
                      product={product}
                      disableDelete={disableDelete}
                      getLogo={getLogo}
                      showButtons={enableAddAndRemoveButtons}
                    />
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box>
                    <Skeleton height={32} width="100%" />
                    <Skeleton height={32} width="100%" />
                    <Skeleton height={32} width="100%" />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          maxHeight: '120px',
          overflow: 'auto',
          pr: 1.7,
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#fff',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#000',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#000',
          },
        }}
      >
        {renderDiscount && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: '25px',
              gap: 7,
            }}
          >
            <Box sx={{ width: 70 }}>
              <Typography
                sx={{
                  fontSize: '0.7em',
                  fontWeight: 700,
                }}
              >
                Descuento
              </Typography>
            </Box>
            <Box sx={{ width: 70, textAlign: 'right' }}>
              <Typography
                sx={{
                  fontSize: '0.7em',
                  fontWeight: 700,
                }}
              >
                {invoiceDetail.discount}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: '25px',
            gap: 7,
          }}
        >
          <Box sx={{ width: 70 }}>
            <Typography
              sx={{
                fontSize: '0.7em',
                fontWeight: 700,
              }}
            >
              Subtotal
            </Typography>
          </Box>
          <Box sx={{ width: 70, textAlign: 'right' }}>
            <Typography
              sx={{
                fontSize: '0.7em',
                fontWeight: 700,
              }}
            >
              {invoiceDetail.subtotal}
            </Typography>
          </Box>
        </Box>

        {invoiceDetail.taxes?.map((tax) => (
          <Box
            key={tax.taxLabel}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: '25px',
              gap: 7,
            }}
          >
            <Box sx={{ width: 70 }}>
              <Typography
                sx={{
                  fontSize: '0.7em',
                  fontWeight: 700,
                }}
              >
                {tax.taxLabel}
              </Typography>
            </Box>
            <Box sx={{ width: 70, textAlign: 'right' }}>
              <Typography
                sx={{
                  fontSize: '0.7em',
                  fontWeight: 700,
                }}
              >
                {tax.taxValue}
              </Typography>
            </Box>
          </Box>
        ))}

        <Box
          key={'total'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: '25px',
            gap: 7,
          }}
        >
          <Box sx={{ width: 70 }}>
            <Typography
              sx={{
                fontSize: '0.7em',
                fontWeight: 700,
              }}
            >
              Total
            </Typography>
          </Box>
          <Box sx={{ width: 70, textAlign: 'right' }}>
            <Typography
              sx={{
                fontSize: '0.7em',
                fontWeight: 700,
              }}
            >
              {invoiceDetail.total}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Stack>
  )
}
