import { LoadingButton } from '@mui/lab'
import { Button, Icon, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { AnimatedLink } from 'components'
import { useAuth, useFeedback, useToggle } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { ISolutionReference } from 'marketplace-common'
import { IUserRequest, Options } from 'types'

type Props = {
  solutionReference?: ISolutionReference
  onClickLogin?: (user: string, password: string) => void
  stylized?: boolean
  handleChangeOption?: (option: Options) => void
  fromModal?: boolean
  dataUser?: IUserRequest
}

/**
 *
 * @returns Form component with all the login authentication process logic
 */
export const LoginForm = (props: Props) => {
  const { onClickLogin, stylized, handleChangeOption, fromModal, dataUser } = props
  const { showMessage } = useFeedback()
  const { toggle, value: show } = useToggle()
  const { login, loading, user } = useAuth()

  const [email, setEmail] = React.useState<string>(dataUser !== undefined ? dataUser!.email : '')
  const [password, setPassword] = React.useState<string>(
    dataUser !== undefined ? dataUser!.password : ''
  )
  const location = useLocation()
  const from: string | undefined = (location.state as any)?.from

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (onClickLogin) {
      onClickLogin(email, password)
    } else {
      await handleLogin()
    }
  }

  const handleLogin = async () => {
    try {
      const isSameUserToLogin = user.email === email
      if (isSameUserToLogin) {
        throw new Error('El usuario ya se encuentra Iniciado Sesión, por favor use con otra cuenta')
      }
      await login(email, password, true, from)
    } catch (error: any) {
      showMessage(error.message as string, 'error', { horizontal: 'right', vertical: 'top' })
    }
  }

  return (
    <Stack
      spacing={{ xs: 1, sm: 2, md: 4 }}
      onSubmit={handleSubmit}
      component="form"
      height="100%"
      justifyContent="center"
      sx={{ my: stylized ? 0 : { xs: 3, sm: 4, md: 6 } }}
    >
      <Stack spacing={1}>
        <Typography fontWeight={600} variant="caption">
          {'Correo electrónico'}
        </Typography>
        <TextField
          placeholder="quindefee@biosferasoft.com"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon color="action">{'email'}</Icon>
              </InputAdornment>
            ),
          }}
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Typography fontWeight={600} variant="caption">
          {'Contraseña'}
        </Typography>
        <TextField
          type={show ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={toggle} variant="text">
                  {show ? 'Ocultar' : 'Mostrar'}
                </Button>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </Stack>
      {/* Boton de login */}
      <Stack alignItems={'center'} spacing={2} py={{ xs: 1, sm: 4 }}>
        <LoadingButton fullWidth size="medium" type="submit" loading={loading}>
          {'Ingresar'}
        </LoadingButton>
        {fromModal ? (
          <Button
            fullWidth
            size="medium"
            color="secondary"
            onClick={() => {
              if (fromModal && handleChangeOption) {
                handleChangeOption(ROUTES.signUp as Options)
              }
            }}
          >
            <Typography display={'flex'} fontWeight={500} variant="subtitle2" textAlign="center">
              {'Registrarse'}
            </Typography>
          </Button>
        ) : (
          <AnimatedLink to={`${ROUTES.signUp}`} type={'circle'} style={{ width: '100%' }} fullWidth>
            <Button fullWidth size="medium" color="secondary">
              <Typography display={'flex'} fontWeight={500} variant="subtitle2" textAlign="center">
                {/* {"¿Aún no tienes una cuenta? Adquierela aquí"} */}
                {'Registrarse'}
              </Typography>
            </Button>
          </AnimatedLink>
        )}
      </Stack>
      <Stack alignItems="center">
        <AnimatedLink
          to={`${ROUTES.support}/${ROUTES.forgotPassword}`}
          type={'circle'}
          style={{ width: '100%' }}
        >
          <Typography
            display={'flex'}
            fontWeight={500}
            variant="subtitle1"
            textAlign="center"
            color="textSecondary"
            sx={{ textDecoration: 'underline' }}
          >
            {'Olvide mi contraseña'}
          </Typography>
        </AnimatedLink>
        <AnimatedLink
          to={`${ROUTES.feedback}/${ROUTES.resendEmailVerification}`}
          type={'circle'}
          style={{ width: '100%' }}
        >
          <Typography
            display={'flex'}
            fontWeight={500}
            variant="subtitle2"
            textAlign="center"
            color="textSecondary"
            sx={{ textDecoration: 'underline' }}
          >
            {'Reenviar correo de activación'}
          </Typography>
        </AnimatedLink>
      </Stack>
    </Stack>
  )
}
