import { format } from 'date-fns'
import { useFeedback, useGetCompany, useLocalStorage, useNavigate } from 'hooks'
import {
  GA_EVENT,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USER_KEY,
  PAYMENT_PERSONAL_INFO_KEY,
  ROUTES,
  closeTutorialService,
  getBrowser,
  getIpClient,
  getUserByTokenService,
  loginService,
  logoutService,
  updateAllowedTermsAndConditionService,
  updateUserService,
} from 'lib'
import { ICompany, INavigatorNative, IUser } from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useLocation, useNavigate as useRouterNavigate, useSearchParams } from 'react-router-dom'
import { IUserInformation } from 'types'
import { trackGoogleAnalyticsEventWithData } from 'utils'

interface Props {
  user: IUser
  validatingSession: boolean
  login: (email: string, password: string, redirect?: boolean, from?: string) => Promise<IUser>
  logout: () => void
  loading: boolean
  loadingAutoLogin: boolean
  handleLoading: (load: boolean) => void
  isAuthenticated: boolean
  updateUserInfo: (información: IUserInformation) => Promise<void>
  ip: string
  showSelectProcess: boolean
  handleShowSelectProcess: (showDialog: boolean) => void
  acceptTermsAndConditions: (userId: number) => Promise<void>
  refuseTermsAndCondition: () => void
  loadingButtons: LoadOptions[]
  showTutorial: boolean
  handleShowTutorial: (showTutorial: boolean) => void
  closeTutorial: () => Promise<void>
}

export const AuthContext = React.createContext({} as Props)

const HOME_ROUTE = `${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`
const PROCESSES_ROUTE = `${ROUTES.processes}`

type LoadOptions = 'accept' | 'refuse'

const AuthProvider: React.FC = ({ children }) => {
  const { showMessage } = useFeedback()
  const { getCompanyById } = useGetCompany()
  const [user, setUser] = React.useState<IUser>({} as IUser)
  const [loading, setLoading] = React.useState(false)
  const [loadingAutoLogin, setLoadingAutoLogin] = React.useState(false)
  const [validatingSession, setValidatingSession] = React.useState(true)
  const { getItem, storeItem, deleteItem } = useLocalStorage()
  const { animatedNavigation } = useNavigate()
  const navigate = useRouterNavigate()
  const location = useLocation()

  const [ip, setIp] = React.useState('')
  const [showTutorial, setShowTutorial] = React.useState(false)
  const [userPressShowTutorial, setUserPressShowTutorial] = React.useState(false)
  const [company, setCompany] = React.useState<ICompany>()
  const [coordinates, setCoordinates] = React.useState<{ latitude: number; longitude: number }>({
    latitude: 0,
    longitude: 0,
  })
  const [showSelectProcess, setShowSelectProcess] = React.useState<boolean>(true)
  const [loadingButtons, setLoadingButtons] = React.useState<LoadOptions[]>([])
  const [searchParams] = useSearchParams()

  const handleLoading = (load: boolean) => setLoading(load)

  const handleLoadingButtons = (dialogId: LoadOptions) =>
    setLoadingButtons((prev) => [...prev, dialogId])
  const handleCloseLoadingButtons = (load: LoadOptions) =>
    setLoadingButtons((prev) => prev.filter((option) => option !== load))

  const handleShowSelectProcess = (showDialog: boolean) => setShowSelectProcess(showDialog)

  const login = async (email: string, password: string, redirect?: boolean, from?: string) => {
    try {
      setLoading(true)
      /*get data required save in sesion user*/
      const agentBrand = getBrowser(
        window.navigator.userAgent,
        (window.navigator as INavigatorNative).userAgentData
      )
      const coordenadas = `La:${coordinates.latitude} Lo:${coordinates.longitude}`
      /**Consume security login service */
      const response = await loginService(email, password, agentBrand, coordenadas, ip)
      setLoading(false)
      setValidatingSession(false)

      const acceptedTermsAndConditions = Boolean(response.allowedConditionTerms)
      if (!acceptedTermsAndConditions) handleShowSelectProcess(false)

      /**Store the user response */
      storeItem(LOCAL_STORAGE_TOKEN_KEY, (response as IUser).token)
      handleShowTutorialByCompany(response?.identification)
      setUser(response)
      ReactPixel.track('login', {
        identification: response.identification,
        userName: response.userName,
      })
      const { identification, userId } = response as IUser
      const gaData = {
        user_id: userId,
        company_id: identification,
        login_date: format(new Date(), 'dd-MM-yyyy'),
      }
      trackGoogleAnalyticsEventWithData('Button', GA_EVENT.LOGIN_EVENT, 'success login', gaData)
      /**Navigate to the main route */
      redirect && animatedNavigation(from ?? HOME_ROUTE, 'circle', { replace: true })
      return response
    } catch (error: any) {
      setLoading(false)
      throw new Error(error.message)
    }
  }

  const logout = React.useCallback(async () => {
    /**Delete the stored user */
    try {
      setLoading(true)
      handleLoadingButtons('refuse')
      await logoutService(user.userId)
      deleteItem(LOCAL_STORAGE_TOKEN_KEY)
      deleteItem(PAYMENT_PERSONAL_INFO_KEY)

      setUser({} as IUser)
      animatedNavigation(`${ROUTES.login}`, 'circle', { replace: true })
    } catch (error: any) {
      showMessage(
        'No se pudo salir de tu cuenta, inténtalo en un momento, o comunícate con soporte  ',
        'error'
      )
    } finally {
      setLoading(false)
      handleCloseLoadingButtons('refuse')
    }
  }, [user])

  const isAuthenticated = React.useCallback(() => {
    return Boolean(user) && Boolean(user.userId)
  }, [user])

  const updateUserInfo = async (información: IUserInformation) => {
    try {
      setLoading(true)
      await updateUserService(user.userId, información)
      setUser((current) => {
        const newUser = {
          ...current,
          ...información,
        }
        storeItem(LOCAL_STORAGE_USER_KEY, newUser)
        return newUser
      })
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getUserInformationByToken = React.useCallback(
    async (token: string) => {
      try {
        setLoading(true)
        const response = (await getUserByTokenService(token)) as IUser

        ReactPixel.track('loginByToken', {
          identification: response.identification,
          userName: response.userName,
          redirectPlatform: 'Quinde',
        })

        handleShowTutorialByCompany(response?.identification)
        setUser(response)
        storeItem(LOCAL_STORAGE_TOKEN_KEY, token)
        const from = (location.state as any)?.from
        if (from) {
          navigate(`${from}`, { replace: true })
        }
        /**Check if the current location is a public route */
        if (
          location.pathname === `/${ROUTES.login}` ||
          location.pathname === `${ROUTES.root}` ||
          location.pathname.includes(`${ROUTES.user}`)
        ) {
          navigate(HOME_ROUTE, { replace: true })
        }
        setValidatingSession(false)
      } catch (error: any) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    [getItem, location]
  )

  React.useEffect(() => {
    const isProcessesPage = location.pathname.includes(PROCESSES_ROUTE)

    const token = searchParams.get('id')
    if (token === null || isProcessesPage) {
      return
    }
    getUserInformationByToken(token!)
    return () => {
      window.onmessage = null
    }
  }, [getUserInformationByToken])

  const getIp = React.useCallback(async () => {
    try {
      const ip = (await getIpClient()) as string
      setIp(ip)
    } catch (e) {
      return ''
    }
  }, [])

  const getCoordinates = () => {
    if (navigator.geolocation) {
      const success = (position: any) => {
        setCoordinates({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      }
      navigator.geolocation.getCurrentPosition(success, function (msg) {
        console.error(msg)
      })
    }
  }

  const acceptTermsAndConditions = async (userId: number) => {
    handleLoadingButtons('accept')
    try {
      const response = await updateAllowedTermsAndConditionService(userId)
      setUser(response)
      setLoading(false)
      handleShowSelectProcess(true)
    } catch (error: any) {
      throw new Error(error.message)
    } finally {
      handleCloseLoadingButtons('accept')
    }
  }

  const refuseTermsAndCondition = () => {
    logout()
  }

  const handleShowTutorialByCompany = async (userId: string) => {
    try {
      const result = await getCompanyById(userId)
      setCompany(result)
      setShowTutorial(result.showTutorial)
    } catch (error) {
      console.error(error)
    }
  }

  const closeTutorial = async () => {
    if (userPressShowTutorial) {
      setUserPressShowTutorial(false)
      setShowTutorial(false)
      return
    }
    try {
      const existsCompanyId = Boolean(company?.companyId)
      if (!existsCompanyId) return
      await closeTutorialService(company?.companyId!)
      setShowTutorial(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleShowTutorial = (show: boolean) => {
    setUserPressShowTutorial(true)
    setShowTutorial(show)
  }

  const handleAutoLogin = async () => {
    const token = getItem(LOCAL_STORAGE_TOKEN_KEY)
    if (!Boolean(token)) {
      return
    }
    setLoadingAutoLogin(true)

    try {
      await getUserInformationByToken(token)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingAutoLogin(false)
    }
  }

  React.useEffect(() => {
    getCoordinates()
    handleAutoLogin()
  }, [])

  React.useEffect(() => {
    getIp()
  }, [getIp])

  return (
    <AuthContext.Provider
      value={{
        user,
        validatingSession,
        isAuthenticated: isAuthenticated(),
        loading,
        loadingAutoLogin,
        handleLoading,
        ip,
        login,
        logout,
        updateUserInfo,
        showSelectProcess,
        handleShowSelectProcess,
        acceptTermsAndConditions,
        refuseTermsAndCondition,
        loadingButtons,
        showTutorial,
        closeTutorial,
        handleShowTutorial,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
