import { useAuth, useLocalStorage, useValidateCompanyInformation } from 'hooks'
import {
  constLocalStorage,
  createCompanyInformationDefaultService,
  createCompanyInformationDisabledService,
  createCompanyInformationEnabledService,
  getCompanyInformationService,
} from 'lib'
import { ICompanyInformation } from 'marketplace-common'
import React from 'react'

type Hook = {
  companyInformation: ICompanyInformation
  alreadyExistsCompanyInformation: boolean
  changeCompanyInformation: (companyInfo: ICompanyInformation) => void
  changeAlreadyExistsCompanyInformation: (state: boolean) => void
  handleSaveCompanyInformationDefault: () => Promise<number>
  handleSaveCompanyInformationEnabled: () => Promise<number>
  handleSaveCompanyInformationDisabled: () => Promise<number>
  companyInformationList: ICompanyInformation[]
  handleCompanyInformationList: (companyId: string) => {}
  handleSaveCompanyInformationDisabledFromLocalStorage: (
    companyInformation: ICompanyInformation
  ) => Promise<number>
}

export const useCompanyInformation = (): Hook => {
  const { user, isAuthenticated } = useAuth()
  const { storeItem, deleteItem } = useLocalStorage()
  const [companyInformation, setCompanyInformation] = React.useState<ICompanyInformation>(
    {} as ICompanyInformation
  )
  const [companyInformationList, setCompanyInformationList] = React.useState<ICompanyInformation[]>(
    []
  )
  const { noAllFields } = useValidateCompanyInformation()
  const [alreadyExistsCompanyInformation, setAlreadyExistsCompanyInformation] =
    React.useState<boolean>(false)

  const changeCompanyInformation = (companyInfo: ICompanyInformation) => {
    const companyId = user.identification
    if (!isAuthenticated) {
      storeItem(constLocalStorage.COMPANY_INFO, { ...companyInfo, companyId })
    }
    const companyInformation = { ...companyInfo, companyId }

    if (isAuthenticated && noAllFields(companyInformation)) {
      deleteItem(constLocalStorage.COMPANY_INFO)
    }

    setCompanyInformation({ ...companyInfo, companyId })
  }

  const changeAlreadyExistsCompanyInformation = (state: boolean) =>
    setAlreadyExistsCompanyInformation(state)

  const handleSaveCompanyInformationDefault = async () => {
    try {
      const result = await createCompanyInformationDefaultService(companyInformation)
      return result.companyInformationId
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSaveCompanyInformationEnabled = async () => {
    try {
      const result = await createCompanyInformationEnabledService(companyInformation)
      return result.companyInformationId
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSaveCompanyInformationDisabled = async () => {
    try {
      const result = await createCompanyInformationDisabledService(companyInformation)
      return result.companyInformationId
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSaveCompanyInformationDisabledFromLocalStorage = async (
    companyInformation: ICompanyInformation
  ) => {
    try {
      const result = await createCompanyInformationDisabledService(companyInformation)
      return result.companyInformationId
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCompanyInformationList = async (companyId: string) => {
    try {
      const response = await getCompanyInformationService(companyId)
      setCompanyInformationList(response)
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    companyInformation,
    alreadyExistsCompanyInformation,
    changeCompanyInformation,
    changeAlreadyExistsCompanyInformation,
    handleSaveCompanyInformationDefault,
    handleSaveCompanyInformationEnabled,
    handleSaveCompanyInformationDisabled,
    companyInformationList,
    handleCompanyInformationList,
    handleSaveCompanyInformationDisabledFromLocalStorage,
  }
}
