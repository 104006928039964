export interface IStep {
  stepId: number
  optional?: boolean
  label: string
  index: number
  description?: string
  reference: StepReference
  icon: string
}

export type StepReference =
  | 'account'
  | 'pickProduct'
  | 'payment'
  | 'invoice'


export enum  StepReferenceName{
  PICK_PRODUCT = 'pickProduct',
  INVOICE = 'invoice',
  ACCOUNT = 'account',
  PAYMENT = 'payment',
}
