import { ProcessLayout } from 'layouts'
import { PARAMS, ROUTES } from 'lib'
import React, { Suspense } from 'react'

import { RouteObject } from 'react-router-dom'

const Steps = React.lazy(() => import('pages/Steps/Steps'))

const StepsLayout = React.lazy(() => import('layouts/StepsLayout'))

const MarketplaceRoutes: RouteObject = {
  path: ROUTES.processes,
  element: <ProcessLayout />,
  children: [
    {
      path: PARAMS.mainSolutionReference,
      element: (
        <Suspense fallback={<></>}>
          <StepsLayout />
        </Suspense>
      ),
      children: [
        {
          path: ROUTES.none,
          element: (
            <React.Suspense fallback={<></>}>
              <Steps />
            </React.Suspense>
          ),
        },
      ],
    },
  ],
}

export default MarketplaceRoutes
