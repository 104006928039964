import { isAfter } from 'date-fns'
import { useCart } from 'hooks/cart/useCart'
import { getProcessStepsService } from 'lib'
import React from 'react'
import { IStep, StepReferenceName } from 'types'
import { IProcess, ISpecialProduct } from 'marketplace-common'
import { useAuth, useLoading } from 'hooks'
type Hook = {
  steps: IStep[]
  currentStep: IStep | undefined
  currentIndex: number
  getEnableSteps: StepReferenceName[]
  addEnableSteps: (reference: StepReferenceName) => void
  deleteEnableSteps: (reference: StepReferenceName) => void
  changeStepIndex: (index: number) => void
  switchNextStep: (shopToAnotherAccount?: boolean) => void
  handleResetSteps: () => void
  getStepCurrentByProcess: (process: IProcess) => Promise<void>
  getCurrentStepValidation: (process: IProcess) => Promise<number>
  loading: boolean
  payInProcessOrComplete: (inProcessOrCompleted: boolean) => void
  inProcessOrCompleted: boolean
  hasExpiredSpecialProduct: boolean
}

export const useProcessStep = (): Hook => {
  const [steps, setSteps] = React.useState<IStep[]>([])
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [enableSteps, setEnableSteps] = React.useState<StepReferenceName[]>([])
  const [inProcessOrCompleted, setInProcessOrCompleted] = React.useState(false)
  const { getProductsByProcessId } = useCart()
  const [loading, setLoading] = React.useState(false)
  const [hasExpiredSpecialProduct, setHasExpiredSpecialProduct] = React.useState<boolean>(false)
  const { isAuthenticated } = useAuth()
  const { addLoadingReference, removeLoadingReference } = useLoading()
  const getSteps = async () => {
    try {
      addLoadingReference('step')
      const stepsResult = await getProcessStepsService()
      // sort steps by index from table step in column step_index
      stepsResult.sort((a: IStep, b: IStep) => a.index - b.index)
      setSteps(stepsResult)
      return stepsResult as IStep[]
    } catch (error) {
      console.log('Error get Steps from services', error)
    } finally {
      removeLoadingReference('step')
    }
  }

  /***
   * @description validate the current step of the process and set the current step
   * @param process: IProcess to validate the current step
   * @return return the index of the current step depending on the process
   */
  const getCurrentStepValidation = async (process: IProcess) => {
    setLoading(true)
    const existCompanyInformation = Boolean(process.companyInformationId)
    const existAssignedCompany = Boolean(process.assignedCompanyId)
    const shopForDifferentCompany =
      process.assignedCompanyId !== process.companyId && process.assignedCompanyId === null
    try {
      const productsByProcess = await getProductsByProcessId(process.processId)
      const isProducts = productsByProcess.length > 0
      const firstStep = productsByProcess.length === 0
      const secondStep = isProducts && !existCompanyInformation
      const thirdStep = isProducts && existCompanyInformation && shopForDifferentCompany
      const fourStep = isProducts && existCompanyInformation && existAssignedCompany
      if (firstStep) {
        return 0
      }
      if (secondStep) {
        return 1
      }
      if (thirdStep) {
        return 2
      }
      if (fourStep) {
        return 3
      }
      return 0
    } catch (error) {
      return 0
    } finally {
      setLoading(false)
    }
  }
  /***
   * @description validate the specialProduct Expiration and redirect
   * @returns true if exist specialProduct expirated
   */
  const handleValitadeExpiratedSpecialProducts = (specialProducts: ISpecialProduct[]) => {
    const currentDate = new Date()
    for (const product of specialProducts) {
      const expirationDate = new Date(product.endValidityDate)
      const noExpiredYet = isAfter(new Date(expirationDate), currentDate)
      if (!noExpiredYet) {
        return true
      }
    }
    return false
  }

  /***
   * @description validate the current step of the process and set the current step
   */
  const getStepCurrentByProcess = async (process: IProcess) => {
    try {
      addLoadingReference('stepValidation')
      const isSpecialProductsExpired = handleValitadeExpiratedSpecialProducts(
        process.specialProducts
      )
      const getCurrentStep = await getCurrentStepValidation(process)
      // const steps = await getSteps()
      const steps = await getProcessStepsService()
      steps.sort((a: IStep, b: IStep) => a.index - b.index)

      const shopIsForSameCompany =
        process.assignedCompanyId !== null && process.assignedCompanyId === process.companyId

      const stepsOrder = steps!
        .filter((step) => {
          if (shopIsForSameCompany && step.reference === 'account') {
            return false
          }
          if (step.reference === 'payment' && process.assignedCompanyId === null) {
            return false
          }
          return true
        })
        .map((step) => step.reference)
        .slice(0, isSpecialProductsExpired ? 1 : getCurrentStep) as StepReferenceName[]
      setEnableSteps(stepsOrder)
      setHasExpiredSpecialProduct(isSpecialProductsExpired)
      const secondStep = 1
      if (isSpecialProductsExpired) {
        changeStepIndex(secondStep)
      } else {
        changeStepIndex(getCurrentStep)
      }
    } catch (error) {
      console.log('Error', error)
    } finally {
      removeLoadingReference('stepValidation')
    }
  }

  React.useEffect(() => {
    getSteps()
  }, [])

  /***
   * @description change the current step to the index passed by parameter
   * @param index: number to change the current step
   */
  const getCurrentStep = React.useCallback(() => {
    return steps[currentIndex]
  }, [currentIndex, steps])

  /***
   * @description change the current step to the index passed by parameter
   * @param index: number to change the current step
   */
  const changeStepIndex = (index: number) => setCurrentIndex(index)

  /***
   * @description add a new step to the list of steps that are enabled
   * @param reference: StepReferenceName to add to the list of steps that are enabled
   */
  const addEnableSteps = (reference: StepReferenceName) => {
    setEnableSteps((prev) => [...prev, reference])
  }

  const deleteEnableSteps = (reference: StepReferenceName) => {
    const deleted = enableSteps.filter((step) => step !== reference)
    setEnableSteps(deleted)
  }

  /***
   * @description get the list of steps that are enabled
   * @return return the list of steps that are enabled
   */
  const getEnableSteps = () => {
    return enableSteps
  }

  /***
   * @description change the current step to the next step
   */
  const switchNextStep = (shopToAnotherAccount?: boolean) => {
    setCurrentIndex((current) => {
      const skipAccountStep = !shopToAnotherAccount && current === 1
      if (skipAccountStep && isAuthenticated) {
        return current + 2
      }
      return current + 1
    })
  }

  /***
   * @description reset the list of steps that are enabled and the current step
   */
  const handleResetSteps = () => {
    setSteps([])
    setCurrentIndex(0)
  }

  /***
   * @description validate if the process is in the last step and de payment is in process or completed
   */
  const payInProcessOrComplete = (inProcessOrCompleted: boolean) => {
    setInProcessOrCompleted(inProcessOrCompleted)
  }

  return {
    steps,
    currentStep: getCurrentStep(),
    currentIndex,
    getEnableSteps: getEnableSteps(),
    changeStepIndex,
    switchNextStep,
    handleResetSteps,
    addEnableSteps,
    deleteEnableSteps,
    getStepCurrentByProcess,
    getCurrentStepValidation,
    loading,
    payInProcessOrComplete,
    inProcessOrCompleted,
    hasExpiredSpecialProduct,
  }
}
