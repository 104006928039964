import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Card,
  Divider,
  Icon,
  LinearProgress,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {
  useAuth,
  useCart,
  useFeedback,
  useGetProductsRepeact,
  useGetSolutions,
  useGetSteps,
  useNavigate,
  useProcessStep,
} from 'hooks'
import {
  COMPLETED_TRANSACTION_ID,
  PENDING_TRANSACTION_ID,
  PROCESS_ID_PARAM,
  REJECTED_TRANSACTION_ID,
  ROUTES,
  SEARCH_PARAM,
  TRANSACTION_ICONS,
  cancelProcessService,
  completeProcessService,
  createProcessesService,
  emmitInvoiceService,
} from 'lib'
import { ICartProduct, IProcess } from 'marketplace-common'
import React from 'react'
import { TransactionStateId } from 'types'
import { encryptParam } from 'utils'

type Props = {
  process: IProcess
  lastTransactionInvoiceId: number | undefined
  isRefund: boolean
  hideContinueButton?: boolean
  onRefresh: () => void
  disableCompleteButtom?: boolean
}

export const ProcessSummary = (props: Props) => {
  const {
    process,
    isRefund,
    hideContinueButton,
    onRefresh,
    lastTransactionInvoiceId,
    disableCompleteButtom,
  } = props
  const navigate = useNavigate()
  const { steps } = useGetSteps()
  const { showMessage } = useFeedback()
  const [processLoading, setProcessLoading] = React.useState(false)
  const { getProductsByProcessId, cleanCart, handleChangeProcess } = useCart()
  const [products, setProducts] = React.useState<ICartProduct[]>([])
  const { solutions } = useGetSolutions()
  const { grouped } = useGetProductsRepeact()
  const dataProducts = grouped(products)
  const { animatedNavigation } = useNavigate()
  const { user } = useAuth()
  const { currentStep, getStepCurrentByProcess } = useProcessStep()
  const completedPercentage = currentStep ? ((currentStep!.index + 1) * 100) / steps.length : 0
  const lastProcess = process.history[process.history.length - 1]
  const [completeButtonLoading, setCompleteButtonLoading] = React.useState<boolean>(false)

  const onCancelProcess = React.useCallback(async () => {
    try {
      setProcessLoading(true)
      await cancelProcessService(process.processId)
      handleChangeProcess(undefined)
      showMessage('El carrito fue cancelado', 'success')
      onRefresh()
    } catch (error) {
      showMessage('No se pudo borrar el carrito ', 'error')
    } finally {
      setProcessLoading(false)
    }
  }, [process])

  const completeProcess = async () => {
    try {
      setCompleteButtonLoading(true)
      if (process) {
        await completeProcessService({ processId: process!.processId })
        try {
          await emmitInvoiceService({
            invoiceContractId: lastTransactionInvoiceId,
            processId: process!.processId,
          })
        } catch (emmitError) {
          showMessage('No se pudo emitir la factura, comunícate con soporte', 'error')
        }
        cleanCart()
        navigate.animatedNavigation(
          `${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.solutions}`,
          'circle'
        )
      }
    } catch (error) {
      showMessage('No se pudo completar tu proceso, comunícate con soporte', 'error')
    } finally {
      setCompleteButtonLoading(true)
    }
  }

  const handleGetProducts = async (processId: number) => {
    setProducts(await getProductsByProcessId(process.processId))
  }

  const getCurrentStep = async () => {
    await getStepCurrentByProcess(process!)
  }

  React.useEffect(() => {
    if (Boolean(process)) {
      getCurrentStep()
    }
  }, [process])

  const getLogo = (mainSolutionId: number | undefined) => {
    const solution = solutions.find((solution) => solution.mainSolutionId === mainSolutionId)
    return solution?.logo
  }

  const handleShop = async () => {
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    try {
      const response = await createProcessesService(process)
      const processId = encryptParam(response.processId)

      const route = `${ROUTES.processes}/${'nuna'}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
      animatedNavigation(route)
    } catch (error) {
      console.log('error', error)
    }
  }

  const stateId = React.useMemo(() => {
    const lastElementHistory = [...process.history].pop()
    return lastElementHistory?.state.transactionStateId
  }, [process])

  const noAllowToCancelProcess = React.useMemo(() => {
    return stateId === TransactionStateId.COMPLETADO || stateId === TransactionStateId.PENDIENTE
  }, [stateId])

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          height: 'auto',
          boxSizing: 'border-box',
          maxWidth: { xs: '250px', sm: '400px' },
        }}
      >
        <Box
          sx={{
            bgcolor: 'black',
            width: 2.2,
            display: 'flex',
          }}
        />
        <Stack spacing={2} ml={2} flexGrow={1} pt={2}>
          <Accordion
            sx={{ boxShadow: 'none' }}
            onClick={() => handleGetProducts(process.processId)}
          >
            <AccordionSummary
              sx={{ paddingY: '0px', marginY: '-8px' }}
              expandIcon={<Icon>expand_moreIcon</Icon>}
            >
              <Typography sx={{ fontWeight: 600 }} variant="body1">
                Ver detalle de los Productos
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ marginY: '0px', paddingBottom: '2px' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, textAlign: 'center', paddingY: '0px' }}>
                        Nombre del Producto
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, textAlign: 'center', paddingY: '0px' }}>
                        Cantidad
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.length > 0 ? (
                      dataProducts.map((product) => (
                        <TableRow key={product.productId} sx={{ '& > *': { paddingY: '0px' } }}>
                          <TableCell sx={{ fontSize: '12px', width: '75%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Avatar
                                alt="Remy Sharp"
                                src={getLogo(product.product?.mainSolutionId)}
                                sx={{ width: 24, height: 24 }}
                              />
                              {product !== null &&
                              product !== undefined &&
                              product!.product !== null
                                ? product.product?.name
                                : ''}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center', paddingY: '0px' }}>
                            {product.count}{' '}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow sx={{ '& > *': { paddingY: '0px' } }}>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > *': { paddingY: '0px' } }}>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems={'center'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              width={'96%'}
              pb={1}
            >
              <Typography variant="caption" sx={{ mb: 1 }}>
                {`Paso ${currentStep ? currentStep!.index + 1 : 0} de ${steps.length}`}
              </Typography>
              {!process.complete && (
                <Box display="flex">
                  <Typography variant="caption" color="textSecondary" sx={{ mb: 1, pr: 1 }}>
                    {`Paso actual`}
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      fontWeight={600}
                      sx={{ ml: 1 }}
                    >
                      {currentStep && currentStep.label}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>

            <Box width={'95%'}>
              <LinearProgress
                variant="buffer"
                value={completedPercentage}
                valueBuffer={completedPercentage + (1 / steps.length) * 100}
              />
            </Box>
          </Box>

          {lastProcess?.state.transactionStateId === PENDING_TRANSACTION_ID && (
            <Box pr={2}>
              <Alert severity="info" color="warning">
                <Typography fontWeight={600}>Tu transferencia esta pendiente</Typography>
                <Typography variant="caption">
                  Tu transferencia esta siendo validada, este proceso no suele tardar mas de 24
                  horas. Nosotros te haremos saber cuando esté lista.
                </Typography>
              </Alert>
            </Box>
          )}
          {lastProcess?.state.transactionStateId === REJECTED_TRANSACTION_ID && (
            <Box pr={2}>
              <Alert severity="error">
                <Typography fontWeight={600}>Transferencia rechazada</Typography>
                <Typography variant="caption">
                  {'Tu transferencia fue rechazada por el siguiente motivo: '}
                  <strong>{lastProcess.transactionAdministratorComment}</strong>
                </Typography>
              </Alert>
            </Box>
          )}

          <Box width={'95%'}>
            <Divider />
          </Box>

          {process.complete ? (
            <Box display={'flex'} alignItems="center" height={'30.75px'}>
              <Box
                bgcolor="success.main"
                width={'25px'}
                height={'25px'}
                display="flex"
                justifyContent={'center'}
                alignItems="center"
                mr={1}
                borderRadius={'100vw'}
              >
                <Icon
                  sx={{
                    color: '#FFF',
                  }}
                >
                  {`${isRefund ? TRANSACTION_ICONS[3] : 'done'}`}
                </Icon>
              </Box>
              <Typography variant="caption" color="textSecondary" fontWeight={600} sx={{ ml: 1 }}>
                {`${isRefund ? 'Devolución Realizada ' : 'Finalizado'}`}
              </Typography>
            </Box>
          ) : (
            <Box
              justifyContent={{ xs: 'space-around', md: 'space-evenly' }}
              alignContent={'center'}
              maxHeight={'100vh'}
              display={{ xs: 'grid', md: 'flex' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              paddingBottom={2}
            >
              <Box>
                {(hideContinueButton === undefined || hideContinueButton === false) && (
                  <LoadingButton
                    endIcon={<Icon>{'navigate_next'}</Icon>}
                    loading={completeButtonLoading}
                    disabled={
                      lastProcess?.state.transactionStateId === COMPLETED_TRANSACTION_ID
                        ? disableCompleteButtom
                        : false
                    }
                    onClick={
                      lastProcess?.state.transactionStateId === COMPLETED_TRANSACTION_ID
                        ? () => completeProcess()
                        : () => handleShop()
                    }
                  >
                    <Box ml={1.5}>
                      {lastProcess?.state.transactionStateId === COMPLETED_TRANSACTION_ID
                        ? 'Completar compra'
                        : 'Continuar'}
                    </Box>
                  </LoadingButton>
                )}
              </Box>
              <LoadingButton
                endIcon={<Icon>{'close'}</Icon>}
                loading={processLoading}
                disabled={noAllowToCancelProcess}
                color="error"
                sx={{ mt: { xs: 2, md: '0px' } }}
                onClick={() => {
                  cleanCart()
                  onCancelProcess()
                }}
              >
                <Box ml={1.5}>{'Cancelar'}</Box>
              </LoadingButton>
            </Box>
          )}
        </Stack>
      </Card>
    </>
  )
}
