import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { isAfter } from 'date-fns'

import { useAuth, useCart, useLocalStorage, useProcess } from 'hooks'
import { constLocalStorage } from 'lib'
import { ICartProduct, IPromocionalCode, ISpecialProductInvoiceItem } from 'marketplace-common'
import React from 'react'

export const SpecialProductDetail = ({
  specialProductItem,
  getLogo,
  disableDelete,
  showButtons,
  hasSpecialProducts,
  hideWarningIcon,
}: {
  specialProductItem: ISpecialProductInvoiceItem
  disableDelete?: boolean
  getLogo: (mainSolutionId: number) => string | undefined
  showButtons?: boolean
  hasSpecialProducts?: boolean
  hideWarningIcon?: boolean
}) => {
  const { isAuthenticated } = useAuth()
  const {    
    removeSpecialProductFromCart,
    handleGetProcessProductSpecialProductId,
    loadingShoppingCart,
    addSpecialProductToCart,
    addSpecialProductToCartNotLoged,
    removeSpecialProducFromCartNoLogged,
    groupSpecialProductsBySpecialProductId,
  } = useCart()
  const { process, handleValidateCodeLoged, handleValidateCodeNotLoged, handleChangeCode } =
    useProcess()
  const { getItem, deleteItem } = useLocalStorage()
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const expirationDate = specialProductItem.specialProduct.endValidityDate
  const currentDate = new Date()
  const noExpiredYet = isAfter(new Date(expirationDate), currentDate)
  const uniqueMainSolutionIds = new Set()
  const uniqueProducts = specialProductItem.specialProduct.specialProducts.filter((product) => {
    const mainSolutionId = product.productInformation.mainSolutionId
    if (!uniqueMainSolutionIds.has(mainSolutionId)) {
      uniqueMainSolutionIds.add(mainSolutionId)
      return true
    }
    return false
  })
  const loading = loadingShoppingCart.specialProductId.includes(specialProductItem.specialProductId)
  const cartSpecialProducts = groupSpecialProductsBySpecialProductId(
    specialProductItem.specialProductId
  )
  const onAddSpecialProduct = async () => {
    if (isAuthenticated) {
      await addSpecialProductToCart({
        specialProductId: specialProductItem.specialProductId,
        processId: process!.processId,
      })
    } else {
      addSpecialProductToCartNotLoged(specialProductItem.specialProduct)
    }
  }

  const onRemoveSpecialProduct = async (specialProductId: number) => {
    const specialProductToDelete = cartSpecialProducts
      .filter((p) => p.specialProduct !== null)
      .find(({ specialProduct }) => specialProduct!.specialProductId === specialProductId)

    if (isAuthenticated) {
      const processProductId = handleGetProcessProductSpecialProductId(
        specialProductItem.specialProductId
      )
      const existsProcessId = Boolean(processProductId)
      if (existsProcessId) {
        const specialProducts = await removeSpecialProductFromCart(
          processProductId!,
          specialProductItem.specialProductId
        )
        return specialProducts
      }
    } else {
      if (specialProductToDelete !== undefined) {
        const specialProducts = removeSpecialProducFromCartNoLogged(
          specialProductToDelete.processProductId,
          specialProductId
        )
        return specialProducts
      }
    }
  }

  const handleGetInformation = (specialProductDetailId: number) => {
    return specialProductItem.specialProductDetails.find(
      (specialProduct) => specialProduct.specialProductDetailId === specialProductDetailId
    )
  }

  const getSpecialProductsId = (cartProducts: ICartProduct[]) => {
    if (!Boolean(cartProducts)) {
      return undefined
    }
    const specialProductsId = cartProducts!
      .filter((cartProduct) => Boolean(cartProduct.specialProduct))
      .map((cartProduct) => cartProduct.specialProduct!.specialProductId)
    return specialProductsId
  }

  const existPromotionalCodeAndProcess: boolean = React.useMemo(() => {
    return process !== undefined && process?.promotionalCode?.code !== undefined
  }, [process])

  const deletePromotionalCode = () => {
    deleteItem(constLocalStorage.PROMOTIONAL_CODE)
  }

  const removeSpecialProductAndValidateCode = async () => {
    const specialProducts = await onRemoveSpecialProduct(specialProductItem.specialProductId)
    if (isAuthenticated) {
      if (specialProducts && specialProductItem.applyDiscount) {
        const specialProductsIds = getSpecialProductsId(specialProducts)
        await handleValidateCodeLoged(
          existPromotionalCodeAndProcess,
          process!.promotionalCode!.code,
          undefined,
          specialProductsIds
        )
      }
    } else {
      const promotionalCodeLocal = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode
      if (
        specialProducts &&
        promotionalCodeLocal !== undefined &&
        specialProductItem.applyDiscount
      ) {
        const specialProductsIds = getSpecialProductsId(specialProducts)
        const response = await handleValidateCodeNotLoged(
          specialProductItem.applyDiscount,
          promotionalCodeLocal.code,
          undefined,
          specialProductsIds
        )
        if (response?.promotionalCode === null) {
          deletePromotionalCode()
          handleChangeCode('')
        }
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <AvatarGroup max={4} spacing={'small'}>
              {uniqueProducts.map((product) => (
                <Avatar
                  key={product.specialProductDetailId}
                  src={getLogo(product.productInformation.mainSolutionId)}
                  sx={{
                    width: { xs: 20, sm: 24, md: 28 },
                    height: { xs: 20, sm: 24, md: 28 },
                  }}
                />
              ))}
            </AvatarGroup>
            <Typography
              fontWeight={600}
              color="textSecondary"
              variant="caption"
              align="left"
              pl={1}
              fontSize={'0.75em'}
            >
              {specialProductItem.item}
            </Typography>
            {specialProductItem.applyDiscount && (
              <Tooltip title="Este producto tiene descuento!" placement="top-end" arrow>
                <Icon sx={{ color: '#8ee62a', pl: 0.1 }}>local_offer_outlined</Icon>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: showButtons ? 'space-evenly' : 'flex-end',
              alignItems: 'center',
            }}
          >
            {showButtons && (
              <IconButton
                disabled={loadingShoppingCart.specialProductId.includes(
                  specialProductItem.specialProductId
                )}
                onClick={async (e) => {
                  e.stopPropagation()
                  await removeSpecialProductAndValidateCode()
                }}
              >
                <Icon sx={{ fontSize: 22 }}>remove_circle_outline_icon</Icon>
              </IconButton>
            )}
            {loadingShoppingCart.productId.includes(specialProductItem.specialProductId) ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              <Typography color="textSecondary" fontSize={'0.8em'} fontWeight={600}>
                {specialProductItem.quantity}
              </Typography>
            )}
            {showButtons && (
              <IconButton
                disabled={loadingShoppingCart.productId.includes(
                  specialProductItem.specialProductId
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  onAddSpecialProduct()
                }}
              >
                <Icon sx={{ fontSize: 22 }}>add_circle_outline_icon</Icon>
              </IconButton>
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                textDecoration: 'line-through',
                textDecorationColor: 'red',
                color: 'red',
              }}
              fontSize={'0.8em'}
              fontWeight={600}
            >
              {specialProductItem.priceBeforeDiscount}
            </Typography>

            <Typography fontSize={'0.8em'} fontWeight={600}>
              {specialProductItem.total}
            </Typography>
          </Box>
        </TableCell>

        <TableCell sx={{ textAlign: 'right' }}>
          <Typography fontSize={'0.8em'} fontWeight={600}>
            {specialProductItem.percentage ?? '-'}
          </Typography>
        </TableCell>

        {(!disableDelete || hasSpecialProducts) && (
          <TableCell sx={{ textAlign: 'left' }}>
            <Box
              sx={{ display: 'flex', gap: 0.8, justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {!noExpiredYet && !hideWarningIcon && (
                <Tooltip
                  title="Una promoción ya no está disponible en el marketplace, pero puedes comunicarte con soporte para tener mas información!"
                  placement="top-end"
                  arrow
                >
                  <Icon sx={{ color: '#ff9800', width: '20px' }}>warning_icon</Icon>
                </Tooltip>
              )}

              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  width: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
              </IconButton>

              {!disableDelete && (
                <IconButton
                  disabled={loading}
                  edge="end"
                  sx={{
                    '&:hover': {
                      color: 'red',
                    },
                  }}
                  onClick={async () => {
                    await removeSpecialProductAndValidateCode()
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </Box>
          </TableCell>
        )}
      </TableRow>
      {isExpanded &&
        specialProductItem.specialProduct.specialProducts.map((specialProduct) => (
          <TableRow key={specialProduct.specialProductDetailId}>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  pl: 2,
                }}
              >
                <Avatar
                  src={getLogo(specialProduct.productInformation.mainSolutionId)}
                  sx={{
                    width: { xs: 20, sm: 24, md: 28 },
                    height: { xs: 20, sm: 24, md: 28 },
                  }}
                />
                <Typography
                  fontWeight={600}
                  color="textSecondary"
                  variant="caption"
                  align="left"
                  pl={1}
                  fontSize={'0.75em'}
                >
                  {specialProduct.productInformation.name}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <Typography color="textSecondary" fontSize={'0.8em'} fontWeight={600}>
                {specialProductItem.quantity}
              </Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  gap: 0.5,
                }}
              >
                <Typography
                  sx={{
                    textDecoration: 'line-through',
                    textDecorationColor: 'red',
                    color: 'red',
                  }}
                  fontSize={'0.8em'}
                  fontWeight={600}
                >
                  $
                  {handleGetInformation(specialProduct.specialProductDetailId)?.beforePrice.toFixed(
                    2
                  )}
                </Typography>
                <Typography fontSize={'0.8em'} fontWeight={600}>
                  ${handleGetInformation(specialProduct.specialProductDetailId)?.total.toFixed(2)}
                </Typography>
              </Box>
            </TableCell>

            <TableCell sx={{ textAlign: 'right' }}>
              <Typography color="textSecondary" fontSize={'0.8em'} fontWeight={800}>
                {handleGetInformation(specialProduct.specialProductDetailId)?.discount}%
              </Typography>
            </TableCell>
          </TableRow>
        ))}
    </React.Fragment>
  )
}
