import LoadingButton from '@mui/lab/LoadingButton'
import {
  Avatar,
  Button,
  FormControlLabel,
  Icon,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth, useCart, useGetSolutions } from 'hooks'
import { formatCurrency } from 'lib'
import { IProduct } from 'marketplace-common'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { MAIN_COLORS } from 'styles'

type Props = {
  product: IProduct
  validProduct?: IProduct | null
  onSelect: (product: IProduct) => void
  selected?: boolean
  mainSolutionLogo: string
  handleBtnContinue: () => Promise<void> | void
}

export const ProductCard = (props: Props) => {
  const {
    product: mainProduct,
    onSelect,
    selected,
    mainSolutionLogo,
    handleBtnContinue,
    validProduct,
  } = props
  const [isFlipped, setIsFlipped] = React.useState<boolean>(false)
  const { isAuthenticated, user } = useAuth()
  const { solutions } = useGetSolutions()
  const {
    cartProducts: cart,
    groupProductsByProductId,
    loadingShoppingCart,
    removeFromCart,
    removeFromCartNoLogged,
    removeListFromCart,
    removeListFromCartNoLogged,
    nonEmptyCart,
  } = useCart()

  const [showTooltipDescription, setShowTooltipDescription] = React.useState<boolean>(false)
  const [product, setProduct] = React.useState<IProduct>(validProduct ?? mainProduct)
  const [productSelected, setProductSelected] = React.useState<IProduct | undefined>(undefined)
  const cartProducts = groupProductsByProductId(product.productId)
  const existsProductsOnTheCart = Boolean(cartProducts?.length)
  const productDescriptionRef = React.useRef<HTMLParagraphElement>(null)

  const handleDeleteProduct = (productId: number) => {
    const productToDelete = cartProducts
      .filter((p) => p.product !== null)
      .find(({ product }) => product!.productId === productId)
    const existsProductToDelete: boolean = Boolean(productToDelete)
    if (!existsProductToDelete) return
    isAuthenticated
      ? removeFromCart(productToDelete?.processProductId!, productId)
      : removeFromCartNoLogged(productToDelete?.processProductId!, productId)
  }

  const handleDeleteAllProductById = (productId: number, hasValidity: boolean) => {
    const processProductIdList = cart
      .filter((cartProduct) => cartProduct?.product?.productId === productId)
      .map((cartProduct) => cartProduct.processProductId)
    if (isAuthenticated) removeListFromCart(processProductIdList, productId, hasValidity)
    else removeListFromCartNoLogged(processProductIdList)
  }

  const onSelectOnce = (product: IProduct) => {
    if (!selected) {
      onSelect(product)
      setProductSelected(product)
      return
    }
    handleDeleteAllProductById(product.productId, false)
    setProductSelected(undefined)
  }

  const onChangeValidity = (product: IProduct) => {
    setProduct((prev) => {
      handleDeleteAllProductById(prev.productId, true)
      return product
    })
    onSelect(product)
    setProductSelected(product)
  }

  const onChangeValidityToDelete = (productDelete: IProduct) => {
    handleDeleteAllProductById(productDelete.productId, true)
    setProductSelected(undefined)
  }

  const handleShowTooltip = () => {
    const productDescription = productDescriptionRef.current
    if (productDescription) {
      const exceedsHeight = productDescription?.scrollHeight > productDescription?.clientHeight + 1
      setShowTooltipDescription(exceedsHeight)
    }
  }

  const handleFlippedCard = () => {
    setIsFlipped(true)

    const solution = solutions.find(
      ({ mainSolutionId }) => mainSolutionId === product.mainSolutionId
    )
    const shortProductName =
      solution?.name[0]! +
      solution?.name[1]! +
      product.name
        .split(' ')
        .map((item) => {
          const firstLetter = Boolean(item[0]) ? item[0] : ''
          const secondLetter = Boolean(item[1]) ? item[1] : ''
          return firstLetter + secondLetter
        })
        .join('')

    ReactPixel.track('viewContent' + shortProductName, {
      productId: product.productId,
      userId: Boolean(user.userId) ? user.userId : null,
      value: product.price,
      currency: 'dolar',
      name: product.name,
      detail: product.description,
    })
  }

  React.useEffect(() => {
    handleShowTooltip()
  }, [])

  const cartEmpty = React.useMemo(() => {
    return (
      !nonEmptyCart &&
      loadingShoppingCart.productId.length === 0 &&
      loadingShoppingCart.specialProductId.length === 0
    )
  }, [nonEmptyCart, loadingShoppingCart.productId, loadingShoppingCart.specialProductId])

  const isLoading = React.useMemo(() => {
    return (
      loadingShoppingCart.productId.length !== 0 ||
      loadingShoppingCart.specialProductId.length !== 0
    )
  }, [loadingShoppingCart.productId, loadingShoppingCart.specialProductId])

  React.useEffect(() => {
    if (cartEmpty) {
      setProductSelected(undefined)
    }
  }, [cartEmpty])

  return (
    <Paper
      sx={{
        cursor: 'pointer',
        boxShadow: '10px 10px 8px gray',
        borderRadius: '12px',
        p: 2.5,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 205,
        height: 260,
        maxWidth: 205,
        maxHeight: 260,
        border: selected ? `5px solid ${MAIN_COLORS.secondary.main}` : 'none',
      }}
      component={motion.div}
      whileHover={{
        scale: 1.1,
      }}
      initial={{
        x: 100 * Math.random(),
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        if (isFlipped) {
          return
        }
        onSelectOnce(product)
      }}
    >
      <motion.div
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d',
          transform: isFlipped ? 'rotateY(180deg)' : 'none',
          transition: 'transform 0.5s',
        }}
      >
        <AnimatePresence>
          {!isFlipped && (
            <Box
              component={motion.div}
              initial={{ opacity: 1 }}
              animate={{ opacity: [0, 1], transition: { duration: 0.2, delay: 0.2 } }}
              exit={{ opacity: 0 }}
              sx={{
                width: 205,
                height: 260,
                maxWidth: 205,
                maxHeight: 260,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <Stack justifyContent={'start'} pb={1} sx={{ minHeight: '90px' }}>
                <Tooltip title={product.name}>
                  <Box height={'30px'}>
                    <Typography
                      variant="caption"
                      align="center"
                      fontWeight={700}
                      color="secondary"
                      sx={{
                        fontSize: '0.85rem',
                        lineHeight: '18px',
                        px: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        whiteSpace: 'normal',
                      }}
                    >
                      {product.name}
                    </Typography>
                  </Box>
                </Tooltip>
                <Typography variant="h5" align="center" fontWeight={600}>
                  {`${formatCurrency(product.subTotalPrice ?? 0)}+IVA`}
                </Typography>

                {mainProduct.validProducts ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="caption" align="center" fontSize={12} fontWeight={600}>
                      {'Vigencia (meses)'}
                    </Typography>
                    <RadioGroup
                      row
                      value={productSelected?.productId ?? ''}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                      }}
                    >
                      {mainProduct?.validProducts?.map((validProduct) => {
                        const isSelected = productSelected?.productId === validProduct.productId
                        return (
                          <FormControlLabel
                            key={validProduct.productId}
                            value={validProduct.productId}
                            label={`${validProduct.validityMonths}`}
                            sx={{
                              m: 0,
                              pl: '4px',
                              pr: '8px',
                              '.MuiFormControlLabel-label': {
                                fontSize: 12,
                                fontWeight: isSelected ? 'bold' : 'normal',
                              },
                              '&:hover': {
                                borderRadius: 8,
                                boxShadow: `inset 0 0 0 1px ${MAIN_COLORS.primary.hover}`,
                              },
                            }}
                            control={
                              <Radio
                                value={validProduct.productId}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                  e.stopPropagation()
                                  if (isLoading) {
                                    return
                                  }
                                  if (
                                    !Boolean(mainProduct) &&
                                    !Boolean(mainProduct?.validProducts)
                                  ) {
                                    return
                                  }
                                  const value = (e.target as HTMLButtonElement).value
                                  const validProduct = mainProduct!.validProducts!.find(
                                    (p) => p.productId === Number(value)
                                  )
                                  if (!Boolean(validProduct)) {
                                    return
                                  }
                                  let isProductSelect
                                  if (productSelected) {
                                    isProductSelect =
                                      productSelected.productId === validProduct!.productId
                                  } else {
                                    isProductSelect = false
                                  }

                                  if (!isProductSelect) {
                                    onChangeValidity(validProduct!)
                                  }

                                  if (isProductSelect) {
                                    onChangeValidityToDelete(validProduct!)
                                  }
                                }}
                                sx={{
                                  mr: '4px',
                                  p: 0,
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 16,
                                    '& .Mui-checked': {
                                      color: isSelected ? MAIN_COLORS.primary.main : undefined,
                                    },
                                  },
                                }}
                              />
                            }
                          />
                        )
                      })}
                    </RadioGroup>
                  </Box>
                ) : (
                  <Typography
                    py={'4px'}
                    variant="caption"
                    align="center"
                    fontSize={12}
                    fontWeight={600}
                  >
                    {`Vigencia de ${product.validityMonths} meses`}
                  </Typography>
                )}
              </Stack>
              <Box display="flex" justifyContent={'flex-start'} alignItems={'center'} m={1}>
                <Avatar
                  src={mainSolutionLogo}
                  sx={{
                    cursor: 'pointer',
                    width: { xs: 50, sm: 60 },
                    height: { xs: 50, sm: 60 },
                  }}
                />
                <Tooltip
                  arrow
                  title={showTooltipDescription ? product?.description : ''}
                  placement="right"
                >
                  <Typography
                    ref={productDescriptionRef}
                    pl={1}
                    variant="caption"
                    align="left"
                    fontSize={12}
                    lineHeight={1}
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                  >
                    {product?.description}
                  </Typography>
                </Tooltip>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {isAuthenticated && loadingShoppingCart.productId.includes(product.productId) ? (
                  <LoadingButton fullWidth loading>
                    Cargando...
                  </LoadingButton>
                ) : (
                  <Box>
                    {existsProductsOnTheCart ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <LoadingButton
                          endIcon={<Icon>chevron_right</Icon>}
                          onClick={async (e) => {
                            e.stopPropagation()
                            await handleBtnContinue()
                          }}
                          variant="contained"
                          sx={{ width: '100%' }}
                        >
                          {'Continuar'}
                        </LoadingButton>
                      </Box>
                    ) : (
                      <Button
                        fullWidth
                        onClick={(e) => {
                          e.stopPropagation()
                          onSelect(product)
                        }}
                        variant="contained"
                        endIcon={<Icon>add_shopping_cart</Icon>}
                      >
                        {'Agregar al carrito'}
                      </Button>
                    )}
                  </Box>
                )}
                <Button
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation()
                    handleFlippedCard()
                  }}
                  variant="contained"
                  endIcon={<Icon>feed</Icon>}
                  color="secondary"
                >
                  {'Ver Detalles'}
                </Button>
              </Box>
            </Box>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {selected && !isFlipped && (
            <Box
              sx={{
                position: 'absolute',
                top: -28,
                right: -26,
                width: '25px',
                height: '25px',
                borderRadius: '100%',
              }}
            >
              <Icon
                sx={{
                  position: 'absolute',
                  top: 7,
                  right: 5,
                  borderRadius: '100%',
                }}
                fontSize="large"
                color="success"
                component={motion.span}
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
              >
                check_circle
              </Icon>
            </Box>
          )}
        </AnimatePresence>

        {/* Contenido de la segunda cara */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setIsFlipped(false)
              }}
              size="medium"
              sx={{ pl: 0, pt: 0, pb: 0 }}
            >
              <Icon fontSize="medium">chevron_left</Icon>
              <Typography variant="caption" fontWeight={600}>
                Volver
              </Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              maxHeight: 190,
              overflowY: 'auto',
              marginTop: 1,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Avatar
                  sx={{
                    cursor: 'pointer',
                    width: { xs: 12, sm: 17 },
                    height: { xs: 12, sm: 17 },
                  }}
                  src={mainSolutionLogo}
                >
                  <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>local_offer</Icon>
                </Avatar>
                <Typography variant="caption" align="center" fontWeight={600}>
                  {product.name}
                </Typography>
              </Box>
              <Typography variant="caption" align="center" sx={{ pb: 1 }}>
                {product.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </motion.div>
    </Paper>
  )
}
