import { LoadingButton } from '@mui/lab'
import {
  Box,
  CSSObject,
  Icon,
  Drawer as MuiDrawer,
  Step as MuiStep,
  Stack,
  StepButton,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { StepsMobile } from 'components'
import { useAuth, useLoading, useProcess, useSupport } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { StepReference } from 'types'
import { StepsSekeleton } from './StepsSkeleton'

const drawerWidth = 260

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 35px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 35px)`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
)

type Props = {
  drawerWidth?: number
}

/**
 *
 * @param props drawerWidth: x size of the side menu
 * @returns Navigation component for a main solution process
 */
export const Steps = (props: Props) => {
  const {
    steps,
    currentIndex,
    changeStepIndex,
    getEnableSteps,
    inProcessOrCompleted,
    shopToAnotherAccount,
    loadingCompleteProcess,
  } = useProcess()
  const { openChat } = useSupport()
  const navigate = useNavigate()
  const { isLoadingReference } = useLoading()
  const { isAuthenticated, loading } = useAuth()

  const completed = React.useCallback(
    (reference: StepReference) => {
      return getEnableSteps.some((step) => step === reference)
    },
    [getEnableSteps]
  )

  const handleRequestHelp = () => {
    const message = `Hola necesito ayuda, estoy en el paso ${currentIndex + 1} (${
      steps[currentIndex].label
    })`
    openChat(message)
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const renderStep = React.useMemo(() => {
    if (shopToAnotherAccount) {
      return steps
    } else {
      return steps.filter((step) => step.reference !== 'account')
    }
  }, [shopToAnotherAccount, steps])

  const loadingStep = React.useMemo(() => {
    if (isAuthenticated) {
      return isLoadingReference('step') || isLoadingReference('stepValidation')
    }
    return isLoadingReference('step')
  }, [isLoadingReference, isAuthenticated])

  return (
    <React.Fragment>
      {isMobile ? (
        <StepsMobile
          loadingStep={loadingStep && loading}
          renderStep={renderStep}
          completed={completed}
        />
      ) : (
        <Drawer id={'drawerSteps'} variant="permanent" anchor="left" open={!isMobile}>
          <Box
            bgcolor={'primary.main'}
            display="flex"
            flexDirection={'column'}
            pb={5}
            position="relative"
          >
            <Box
              color="#FFF"
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={'center'}
              justifyContent={'center'}
              pt={4}
            >
              {!(window.innerWidth < 320) && (
                <LoadingButton
                  sx={{
                    cursor: 'pointer',
                    color: '#FFF',
                    bgcolor: '#FFFFFF50',
                    width: isMobile ? 80 : 130,
                    textAlign: 'center',
                  }}
                  size="medium"
                  onClick={() =>
                    navigate(`/${ROUTES.user}/${ROUTES.marketplace}/${ROUTES.processes}`)
                  }
                  loading={loading || loadingCompleteProcess}
                >
                  <Typography
                    variant="caption"
                    fontWeight={400}
                    fontSize={isMobile ? 9.5 : 14}
                    sx={{
                      display: 'flex',
                      gap: 0.3,
                      alignItems: 'center',
                    }}
                  >
                    {isAuthenticated ? 'DashBoard' : 'Login'}
                    <Icon sx={{ padding: 0, margin: 0 }}>
                      {isAuthenticated ? 'dashboard' : 'login'}
                    </Icon>
                  </Typography>
                </LoadingButton>
              )}
            </Box>
          </Box>

          <Box flexGrow={1} marginTop={'55px'} ml={{ md: 6 }}>
            <Stack>
              <Stepper
                nonLinear
                activeStep={currentIndex}
                orientation="vertical"
                id="steeperContainer"
                className="steeper-container-responsive"
              >
                {(loadingStep || loading) && <StepsSekeleton mobileView={isMobile} />}
                {!loadingStep &&
                  !loading &&
                  renderStep.map((step) => {
                    const active = currentIndex === step.index
                    return (
                      <MuiStep
                        key={step.label}
                        disabled={
                          inProcessOrCompleted
                            ? getEnableSteps.includes(step.reference)
                            : !getEnableSteps.includes(step.reference)
                        }
                        completed={completed(step.reference)}
                        active={active}
                        id={`stepInfo${step.index}`}
                      >
                        <StepButton
                          onClick={() => {
                            changeStepIndex(step.index)
                          }}
                          disableRipple
                        >
                          <StepLabel
                            sx={{
                              overflow: 'hidden',
                              wordWrap: 'break-word',
                              whiteSpace: 'normal',
                              textAlign: 'center',
                            }}
                            className="step-label-responsive"
                            StepIconComponent={() => (
                              <Box
                                borderRadius={'100vw'}
                                bgcolor={
                                  completed(step.reference)
                                    ? step.index === currentIndex
                                      ? (theme) => `${theme.palette.secondary.main}`
                                      : 'success.main'
                                    : step.index === currentIndex
                                    ? (theme) => `${theme.palette.secondary.main}`
                                    : (theme) => `${theme.palette.primary.main}20`
                                }
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Icon
                                  sx={{
                                    color:
                                      getEnableSteps.includes(step.reference) ||
                                      step.index === currentIndex
                                        ? 'white'
                                        : 'black',
                                  }}
                                >
                                  {completed(step.reference) ? 'done' : step.icon}
                                </Icon>
                              </Box>
                            )}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={step.index === currentIndex ? 800 : 400}
                              fontSize={step.index === 2 ? 13.8 : 15}
                            >
                              {step.label}
                            </Typography>
                          </StepLabel>
                        </StepButton>
                      </MuiStep>
                    )
                  })}
              </Stepper>
            </Stack>
          </Box>

          <Box
            px={{ xs: 1, sm: 4 }}
            pb={{ xs: 1, sm: 4 }}
            display="flex"
            flexDirection={{ xs: 'column' }}
            justifyContent={{ xs: 'center' }}
            alignItems={{ xs: 'center' }}
          >
            <Typography
              align="center"
              display={'block'}
              onClick={handleRequestHelp}
              sx={{
                mb: 2,
                fontSize: '0.7em',
                textDecoration: 'underline',
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {!isMobile && '¿Tienes alguna dificultad? '}
              <Typography component={'span'} variant="caption" fontWeight={600}>
                {isMobile ? 'Soporte' : ' Contactanos'}
              </Typography>
            </Typography>
          </Box>
        </Drawer>
      )}
    </React.Fragment>
  )
}

/**
 * default props
 */
Steps.defaultProps = {
  drawerWidth: 300,
}
