import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  AnimatedLink,
  ExplorerBtn,
  SolutionArch,
  SolutionColors,
  SolutionLoading,
} from 'components'
import { useAuth, useGetSolutions, useSupport } from 'hooks'
import { ROUTES } from 'lib'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import biosferasoftLogoMain from '../assets/img/logo-biosferasoft-main.webp'

export const PublicLayout = () => {
  const { openChat } = useSupport()
  const { loadingAutoLogin } = useAuth()
  const { solutions } = useGetSolutions()
  const location = useLocation()
  const signUpLocation = location.pathname.includes(ROUTES.signUp)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const filteredSolutions = solutions.filter((solution) => solution.mainSolutionId !== 6)

  if (loadingAutoLogin) return <SolutionLoading />

  return (
    <Box
      bgcolor={(theme) => `${theme.palette.alt?.background}`}
      height="100vh"
      position={'relative'}
      sx={{ overflow: 'auto' }}
    >
      {!isMobile && (
        <Box
          position={'absolute'}
          left={{ sm: '25vw', md: '25vw', lg: '25vw' }}
          top={{ xs: '30vh', sm: '50vh', md: '50vw', lg: '50vh' }}
          id="circlesAnimation"
        >
          <SolutionColors />
        </Box>
      )}

      <Stack
        bgcolor={(theme) => `${theme.palette.alt?.background}80`}
        height="100vh"
        position={'relative'}
        sx={{ backdropFilter: 'blur(55px)' }}
        direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
        justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
      >
        <Box
          pt={{ xs: 18, sm: 0 }}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row-reverse' }}
        >
          <Stack
            alignItems="center"
            display="flex"
            justifyContent="center"
            width={{ xs: '100vw', sm: '60vw', md: '50vw', lg: '50vw', xl: '50vw' }}
            id="leftPanel"
          >
            {isMobile && (
              <>
                <Stack alignItems="center" spacing={1} pb={2} pt={25}>
                  <SolutionArch solutions={filteredSolutions} />
                  <img src={biosferasoftLogoMain} alt={'Biosferasoft'} width="252" height="106" loading="lazy" />
                  {signUpLocation ? (
                    <React.Fragment>
                      <Typography variant="h6" fontWeight={500} color="textSecondary">
                        {'Regístrate para iniciar'}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <Typography variant="h6" fontWeight={500} color="textSecondary">
                      {'Inicia sesión para empezar'}
                    </Typography>
                  )}
                </Stack>
                <Box sx={{ paddingBottom: 3 }}>
                  <ExplorerBtn />
                </Box>
              </>
            )}
            {/* Formulario de login */}
            <Paper
              sx={{
                height: 'auto',
                width: '90%',
                marginLeft: 2,
                marginRight: 2,
              }}
              elevation={15}
            >
              <Outlet />
            </Paper>
          </Stack>

          <Stack
            width={{ xs: '100vw', sm: '40vw', md: '50vw', lg: '50vw', xl: '50vw' }}
            id="rightPanel"
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              pl={{ xs: 2, sm: 4 }}
              pt={{ xs: 2, sm: 0 }}
            >
              {isMobile && signUpLocation && (
                <Typography variant="caption" fontWeight={500}>
                  {
                    'Si alguna vez utilizaste alguno de nuestros productos puedes ingresar con esa cuenta'
                  }
                </Typography>
              )}
              <Stack py={3} spacing={1} direction="row" paddingLeft={'28px'}>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    const message = `Hola requiero ayuda con el registro de mi cuenta`
                    openChat(message)
                  }}
                >
                  {'Ayuda y soporte'}
                </Typography>
                <AnimatedLink to={`${ROUTES.feedback}/${ROUTES.policy}`} type="circle">
                  <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                    {'Políticas de privacidad'}
                  </Typography>
                </AnimatedLink>
                <AnimatedLink to={`${ROUTES.feedback}/${ROUTES.terms}`} type="circle">
                  <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                    {'Términos y condiciones'}
                  </Typography>
                </AnimatedLink>
              </Stack>

              {!isMobile && (
                <Stack alignItems="center" spacing={2} pt={2}>
                  <SolutionArch solutions={filteredSolutions} />
                  <img src={biosferasoftLogoMain} alt={'Biosferasoft'} width="252" height="106" />
                  {signUpLocation ? (
                    <React.Fragment>
                      <Typography variant="h6" fontWeight={500} color="textSecondary">
                        {'Regístrate para iniciar'}
                      </Typography>
                      <Typography variant="caption" fontWeight={500} sx={{ pt: 2 }}>
                        {
                          'Si alguna vez utilizaste alguno de nuestros productos puedes ingresar con esa cuenta'
                        }
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <Typography variant="h6" fontWeight={500} color="textSecondary">
                      {'Inicia sesión para empezar'}
                    </Typography>
                  )}

                  <ExplorerBtn />
                </Stack>
              )}

              <Stack py={3} spacing={5} direction="row">
                <Box display={'flex'} flexDirection="column">
                  <Typography variant="caption" fontWeight={600}>
                    {`Contactos: (02) 2907210 - 0992619086`}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      window.open(
                        'mailto:soporte@biosferasoft.com?subject=Quisiera%20Contactarme%con%soporte',
                        '_blank'
                      )
                    }}
                  >
                    {'soporte@biosferasoft.com'}
                  </Typography>
                </Box>
                <Typography variant="caption" fontWeight={500} color="textSecondary">
                  {` Biosferasoft ${new Date().getFullYear()} ® Todos los derechos reservados`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
