import React from 'react'

type DialogOptions =
  | 'saveInformation'
  | 'selectProcess'
  | 'login'
  | 'productsNeedsRuc'
  | 'confirmProductsNeedsRuc'
type Props = {
  openDialog: DialogOptions[]
  handleOpenDialog: (dialogId: DialogOptions) => void
  handleCloseAllDialog: () => void
  handleCloseDialog: (dialogId: DialogOptions) => void
}

export const ProcessSelectionContext = React.createContext({} as Props)

const ProcessSelectionProvider: React.FC = ({ children }) => {
  const [openDialog, setOpenDialog] = React.useState<DialogOptions[]>([])

  const handleOpenDialog = (dialogId: DialogOptions) => setOpenDialog((prev) => [...prev, dialogId])
  const handleCloseAllDialog = () => setOpenDialog([])
  const handleCloseDialog = (dialogId: DialogOptions) =>
    setOpenDialog((prev) => prev.filter((option) => option !== dialogId))

  return (
    <ProcessSelectionContext.Provider
      value={{
        openDialog,
        handleOpenDialog,
        handleCloseDialog,
        handleCloseAllDialog,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </ProcessSelectionContext.Provider>
  )
}

export default ProcessSelectionProvider
