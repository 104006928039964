import { Box } from '@mui/material'
import { DialogAcceptTermsAndCondition, DialogProcess, OuletSkeleton, Steps } from 'components'
import { SolutionThemeProvider } from 'context'
import { useAuth, useGetProcesses, useLoading, useProcess } from 'hooks'
import { useProcessSelection } from 'hooks/processSelection/useProcessSelection'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const StepsLayout = () => {
  const { loadingInitialize, initializeConfigureAfterLogin } = useProcess()
  const { isLoadingReference } = useLoading()
  const { isAuthenticated, user, loading, showSelectProcess } = useAuth()
  const { handleCloseAllDialog, openDialog } = useProcessSelection()
  const { processes } = useGetProcesses(user.identification)

  const loadingCurrentStep = React.useMemo(() => {
    return isAuthenticated && isLoadingReference('stepValidation')
  }, [isAuthenticated, isLoadingReference])

  const loadingByReference = React.useMemo(() => {
    return isLoadingReference('solutionsByReference')
  }, [isLoadingReference])

  return (
    <React.Fragment>
      <DialogAcceptTermsAndCondition />
      <Box sx={{ display: 'flex', minHeight: '100vh', width: '100vw' }}>
        <Steps drawerWidth={280} />
        <Box
          pb={{ xs: 12, sm: 8, md: 0 }}
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: '100%',
            height: 'fit-content',
            bgcolor: (theme) => (theme.palette.mode === 'light' ? '#EFEFEF' : '#000'),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <SolutionThemeProvider>
              <React.Fragment>
                {loadingCurrentStep || loadingByReference || loading ? (
                  <OuletSkeleton />
                ) : (
                  <Outlet />
                )}
                <DialogProcess
                  openDialog={openDialog.includes('selectProcess') && showSelectProcess}
                  loadingInitialize={loadingInitialize}
                  processId={processes[0] && processes[0].processId}
                  handleCloseDialog={() => {
                    handleCloseAllDialog()
                  }}
                  initializeConfigureAfterLogin={initializeConfigureAfterLogin}
                />
              </React.Fragment>
            </SolutionThemeProvider>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export { StepsLayout as default } from './StepsLayout'
