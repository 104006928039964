import { ILanding } from 'types'

export const LANDINGS_PAGES: ILanding = {
  1: process.env.REACT_APP_LADING_PAGE_QUINDE ?? '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
}
