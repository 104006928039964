import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  AvatarGroup,
  Button,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { Box } from '@mui/system'
import { format, formatDistance, parseISO } from 'date-fns'
import ReactPixel from 'react-facebook-pixel'
import { es } from 'date-fns/locale'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth, useCart, useLocalStorage, useProcess } from 'hooks'
import { ROUTES, constLocalStorage, formatCurrency } from 'lib'
import { ISpecialProduct } from 'marketplace-common'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { MAIN_COLORS, SOLUTION_COLORS } from 'styles'

type Props = {
  specialProduct: ISpecialProduct
  mainSolutionLogos: string[]
  handleBtnContinue: () => Promise<void> | void
  identifierOffer?: boolean
}

export const CardPromo = (props: Props) => {
  const { specialProduct, mainSolutionLogos, handleBtnContinue, identifierOffer } = props
  const { isAuthenticated, user } = useAuth()

  const { storeItem } = useLocalStorage()
  const {
    addSpecialProductToCart,
    loadingShoppingCart,
    cartProducts,
    removeSpecialProductFromCart,
    handleGetProcessProductSpecialProductId,
    addSpecialProductToCartNotLoged,
    removeSpecialProducFromCartNoLogged,
    groupSpecialProductsBySpecialProductId,
  } = useCart()
  const { process } = useProcess()
  const cartSpecialProducts = groupSpecialProductsBySpecialProductId(
    specialProduct.specialProductId
  )
  const [isFlipped, setIsFlipped] = React.useState<boolean>(false)
  const uniqueLogos = [...new Set(mainSolutionLogos)]
  const daysRemaining = formatDistance(new Date(specialProduct.endValidityDate), new Date(), {
    locale: es,
  })

  const saveProcessCreateDate = () => {
    const currentDate = new Date()
    const formattedDate = currentDate.toISOString().slice(0, 10)
    storeItem(constLocalStorage.PROCESS_CREATE, formattedDate)
  }

  const onRemoveSpecialProduct = async (specialProductId: number) => {
    const specialProductToDelete = cartSpecialProducts
      .filter((p) => p.specialProduct !== null)
      .find(({ specialProduct }) => specialProduct!.specialProductId === specialProductId)

    if (isAuthenticated) {
      const processProductId = handleGetProcessProductSpecialProductId(
        specialProduct.specialProductId
      )
      const existsProcessId = Boolean(processProductId)
      if (existsProcessId) {
        removeSpecialProductFromCart(processProductId!, specialProduct.specialProductId)
      }
    } else {
      specialProductToDelete !== undefined &&
        removeSpecialProducFromCartNoLogged(
          specialProductToDelete.processProductId,
          specialProductId
        )
    }
  }

  const onAddSpecialProduct = async () => {
    if (isAuthenticated) {
      await addSpecialProductToCart({
        specialProductId: specialProduct.specialProductId,
        processId: process!.processId,
      })
    } else {
      saveProcessCreateDate()
      addSpecialProductToCartNotLoged(specialProduct)
    }

    const shortProductName = specialProduct.name
      .split(' ')
      .map((item) => {
        const firstLetter = Boolean(item[0]) ? item[0] : ''
        const secondLetter = Boolean(item[1]) ? item[1] : ''
        return firstLetter + secondLetter
      })
      .join('')

    ReactPixel.track('addCart' + shortProductName, {
      promotionId: specialProduct.specialProductId,
      userId: Boolean(user?.userId) ? user.userId : null,
      value: specialProduct.totalAmount,
      currency: 'dolar',
      name: specialProduct.name,
      detail: specialProduct.description,
    })
  }

  const loading = React.useMemo(
    () => loadingShoppingCart.specialProductId.includes(specialProduct.specialProductId),
    [loadingShoppingCart]
  )

  const existSpecialProductInCart = React.useMemo(() => {
    const existsSpecialProduct = cartProducts?.some(
      (cartProduct) =>
        cartProduct.specialProduct !== null &&
        cartProduct.specialProduct?.specialProductId === specialProduct.specialProductId
    )
    return existsSpecialProduct
  }, [cartProducts, specialProduct])

  const handleFlippedCard = () => {
    setIsFlipped(true)

    const shortProductName = specialProduct.name
      .split(' ')
      .map((item) => {
        const firstLetter = Boolean(item[0]) ? item[0] : ''
        const secondLetter = Boolean(item[1]) ? item[1] : ''
        return firstLetter + secondLetter
      })
      .join('')

    ReactPixel.track('viewContent' + shortProductName, {
      promotionId: specialProduct.specialProductId,
      userId: Boolean(user.userId) ? user.userId : null,
      value: specialProduct.totalAmount,
      currency: 'dolar',
      name: specialProduct.name,
      detail: specialProduct.description,
    })
  }

  return (
    <Paper
      sx={{
        cursor: 'pointer',
        boxShadow: '10px 10px 8px gray',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 205,
        height: 260,
        maxWidth: 205,
        p: 2.5,
        maxHeight: 260,
        border: existSpecialProductInCart ? `5px solid ${MAIN_COLORS.secondary.main}` : 'none',
      }}
      component={motion.div}
      whileHover={{
        scale: 1.1,
      }}
      initial={{
        x: 100 * Math.random(),
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        if (isFlipped) {
          return
        }
        if (existSpecialProductInCart) {
          onRemoveSpecialProduct(specialProduct.specialProductId)
          return
        }
        onAddSpecialProduct()
      }}
    >
      <motion.div
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d',
          transform: isFlipped ? 'rotateY(180deg)' : 'none',
          transition: 'transform 0.5s',
        }}
      >
        {!isFlipped && (
          <Box
            sx={{
              width: 205,
              height: 260,
              maxWidth: 205,
              maxHeight: 260,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              gap: 1,

              position: 'absolute',
              backfaceVisibility: 'hidden',
            }}
          >
            <Icon
              sx={{
                fontSize: { xs: 10, sm: 15, md: 20 },
                color: 'white',
                p: '5px',
                backgroundColor: SOLUTION_COLORS.promo.primary.main,
                borderRadius: '50%',
                position: 'absolute',
                left: '-10px',
                top: '-10px',
                zIndex: -1,
              }}
            >
              local_offer
            </Icon>
            <Stack spacing={1} justifyContent={'space-around'} sx={{ height: '115px' }}>
              <Tooltip title={specialProduct.name}>
                <Box height={'30px'}>
                  <Typography
                    variant="caption"
                    align="center"
                    fontWeight={700}
                    color="secondary"
                    sx={{
                      fontSize: '0.85rem',
                      lineHeight: '18px',
                      px: '20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      whiteSpace: 'normal',
                    }}
                  >
                    {specialProduct.name}
                  </Typography>
                </Box>
              </Tooltip>
              <Typography variant="h5" align="center" fontWeight={600} lineHeight={'10px'} my={2}>
                {`${formatCurrency(Number(specialProduct.totalAmount) ?? 0)}+IVA`}
              </Typography>
              <Box
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" align="center" fontSize={12.5}>
                  {`Promoción válida hasta `}
                </Typography>
                <Typography variant="caption" align="center" fontSize={12.5}>
                  <strong style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {format(parseISO(specialProduct.endValidity), 'dd-MM-yyyy')}
                  </strong>
                </Typography>
              </Box>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AvatarGroup max={4}>
                {uniqueLogos.map((logo, i) => (
                  <Avatar
                    key={i}
                    sx={{
                      cursor: 'pointer',
                      width: identifierOffer ? { xs: 50, sm: 60 } : { xs: 38, sm: 47 },
                      height: identifierOffer ? { xs: 50, sm: 60 } : { xs: 38, sm: 47 },
                      backgroundColor:
                        specialProduct.specialProducts.length > 0
                          ? 'none'
                          : SOLUTION_COLORS.promo.primary.main,
                      color:
                        specialProduct.specialProducts.length > 0
                          ? 'none'
                          : SOLUTION_COLORS.promo.primary.dark,
                    }}
                    src={logo}
                  >
                    <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>local_offer</Icon>
                  </Avatar>
                ))}
              </AvatarGroup>
              {identifierOffer && (
                <Tooltip arrow title={specialProduct?.description} placement="right">
                  <Typography
                    pl={1}
                    variant="caption"
                    align="left"
                    fontSize={12}
                    lineHeight={1}
                    sx={{
                      maxWidth: '100px',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                  >
                    {specialProduct.description}
                  </Typography>
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {loading && (
                <LoadingButton sx={{ width: '100%' }} loading>
                  Cargando...
                </LoadingButton>
              )}

              {existSpecialProductInCart && !loading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <LoadingButton
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={async (e) => {
                      e.stopPropagation()
                      await handleBtnContinue()
                    }}
                    variant="contained"
                    sx={{ width: '100%' }}
                  >
                    {'Continuar'}
                  </LoadingButton>
                </Box>
              )}

              {!loading && !existSpecialProductInCart && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    onAddSpecialProduct()
                  }}
                  variant="contained"
                  endIcon={<Icon>add_shopping_cart</Icon>}
                >
                  {'Agregar al carrito'}
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleFlippedCard()
                }}
                variant="contained"
                endIcon={<Icon>feed</Icon>}
                color="secondary"
              >
                {'Ver Detalles'}
              </Button>
            </Box>
          </Box>
        )}

        {!isFlipped && (
          <AnimatePresence>
            {existSpecialProductInCart && !isFlipped && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -20,
                  right: -20,
                  width: '25px',
                  height: '25px',
                  borderRadius: '100%',
                }}
              >
                <Icon
                  sx={{
                    position: 'absolute',
                    top: 5,
                    right: 2,
                    borderRadius: '100%',
                  }}
                  fontSize="large"
                  color="success"
                  component={motion.span}
                  initial={{
                    scale: 0,
                    opacity: 0,
                  }}
                  exit={{
                    scale: 0,
                    opacity: 0,
                  }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                >
                  check_circle
                </Icon>
              </Box>
            )}
          </AnimatePresence>
        )}

        {/* Contenido de la segunda cara */}
        {isFlipped && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backfaceVisibility: 'hidden',
              transform: 'rotateY(180deg)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setIsFlipped(false)
                }}
                size="medium"
                sx={{ pl: 0, pt: 0, pb: 0, mt: 1 }}
              >
                <Icon fontSize="medium">chevron_left</Icon>
                <Typography variant="caption" fontWeight={600}>
                  Volver
                </Typography>
              </IconButton>
            </Box>
            <Box
              sx={{
                pt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                maxHeight: 190,
                overflowY: 'auto',
                marginTop: 1,
                '&::-webkit-scrollbar': {
                  width: '5px',
                  height: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#fff',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#000',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#000',
                },
              }}
            >
              {specialProduct.specialProducts.map((specialDetail, i) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Avatar
                      sx={{
                        cursor: 'pointer',
                        width: { xs: 12, sm: 17 },
                        height: { xs: 12, sm: 17 },
                      }}
                      src={mainSolutionLogos[i]}
                    >
                      <Icon sx={{ fontSize: { xs: 28, sm: 32, md: 40 } }}>local_offer</Icon>
                    </Avatar>
                    <Typography variant="caption" align="center" fontWeight={600}>
                      {specialDetail.productInformation.name}
                    </Typography>
                  </Box>
                  <Typography variant="caption" align="center" sx={{ pb: 1 }}>
                    {specialDetail.productInformation.description}
                  </Typography>
                </Box>
              ))}
              <Box>
                <Typography
                  variant="h1"
                  align="center"
                  sx={{ pb: 1, fontSize: '12px', textAlign: 'center' }}
                  fontWeight={600}
                >
                  {`Detalle de la promoción `}
                </Typography>
                <Typography variant="caption" align="center" sx={{ pb: 1 }}>
                  {specialProduct.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </motion.div>
    </Paper>
  )
}
