import { useRoutes } from 'react-router-dom'
import PublicRoutes from './PublicRoutes'
import UserRoutes from './UserRoutes'
import ProcessRoutes from './ProcessRoutes'
import FeedbackRoutes from './FeedbackRoutes'
import ShopRoutes from './ShopRoutes'
import SupportRoutes from './SupportRoutes'

export default function Routes() {
    return useRoutes([PublicRoutes, ShopRoutes, UserRoutes, ProcessRoutes, FeedbackRoutes, SupportRoutes])
}
