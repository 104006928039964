import React from 'react'
import { ICompanyInformation } from 'marketplace-common'
import {
  fieldValidationDirection,
  fieldValidationEmail,
  fieldValidationIdentification,
  fieldValidationOnlyLetters,
  fieldValidationPhone,
  fieldValidationRuc,
} from 'utils'
import { IDENTIFICATION_CARD_LENGTH, RUC_LENGTH, validateDocument } from 'lib'

interface IValidateErrors {
  name: string
  middleName: string
  lastName: string
  mail: string
  identification: string
  phoneNumber: string
  address: string
}
const validateErrorsDefault = {
  name: '',
  middleName: '',
  lastName: '',
  mail: '',
  identification: '',
  phoneNumber: '',
  address: '',
} as IValidateErrors

type Hook = {
  noAllFields: (field: any) => boolean
  fieldErrors: IValidateErrors
  setFieldErrors: React.Dispatch<React.SetStateAction<IValidateErrors>>
  validateCompanyInformation: () => boolean
  setCompanyInformation: React.Dispatch<React.SetStateAction<ICompanyInformation>>
}

export const useValidateCompanyInformation = (): Hook => {
  const [companyInformation, setCompanyInformation] = React.useState({} as ICompanyInformation)
  const [fieldErrors, setFieldErrors] = React.useState<IValidateErrors>(validateErrorsDefault)
  const { identification } = companyInformation
  const trimmedIdentification = identification?.trim()

  const validateCompanyInformation = () => {
    const errors: Partial<IValidateErrors> = {}

    if (companyInformation?.name?.trim() === '' || companyInformation?.name === undefined) {
      errors.name = 'El campo Primer Nombre es requerido'
    } else if (!fieldValidationOnlyLetters(companyInformation?.name?.trim())) {
      errors.name = 'No debe contener números ni caracteres especiales'
    }

    if (
      companyInformation?.middleName?.length > 0 &&
      !fieldValidationOnlyLetters(companyInformation?.middleName?.trim())
    ) {
      errors.middleName = 'No debe contener números ni caracteres especiales'
    }

    if (companyInformation?.lastName?.trim() === '' || companyInformation?.lastName === undefined) {
      errors.lastName = 'El campo Apellido es requerido'
    } else if (!fieldValidationOnlyLetters(companyInformation?.lastName?.trim())) {
      errors.lastName = 'No debe contener números ni caracteres especiales'
    }

    if (companyInformation?.mail?.trim() === '' || companyInformation?.mail === undefined) {
      errors.mail = 'El campo Correo Electrónico es requerido'
    } else if (!fieldValidationEmail(companyInformation?.mail?.trim())) {
      errors.mail = 'El correo ingresado no es válido'
    }

    if (!trimmedIdentification || trimmedIdentification === undefined) {
      errors.identification = 'El campo Identificación es requerido'
    } else if (trimmedIdentification.length < IDENTIFICATION_CARD_LENGTH) {
      errors.identification = 'Su identificación debe ser mayor o igual a 10 dígitos'
    } else if (
      trimmedIdentification.length === RUC_LENGTH &&
      !fieldValidationRuc(trimmedIdentification)
    ) {
      errors.identification = 'RUC inválido'
    } else if (
      trimmedIdentification.length === IDENTIFICATION_CARD_LENGTH &&
      !validateDocument(trimmedIdentification)
    ) {
      errors.identification = 'Su identificación no es válida'
    } else if (
      trimmedIdentification.length === IDENTIFICATION_CARD_LENGTH &&
      !fieldValidationIdentification(trimmedIdentification)
    ) {
      errors.identification = 'Ingrese una identificación válida'
    }

    if (
      companyInformation?.phoneNumber?.trim() === '' ||
      companyInformation?.phoneNumber === undefined
    ) {
      errors.phoneNumber = 'El campo Teléfono es requerido'
    } else if (!fieldValidationPhone(companyInformation?.phoneNumber?.trim())) {
      errors.phoneNumber = 'No tiene el formato correcto'
    }

    if (
      companyInformation?.address?.trim() !== undefined &&
      fieldValidationDirection(companyInformation?.address?.trim())
    ) {
      errors.address = 'La dirección no debe contener [{}[]!¡¿?%$=*+]'
    }

    setFieldErrors(errors as IValidateErrors)

    const fieldsAreOk: boolean = Object.keys(errors).length === 0
    return fieldsAreOk
  }

  const noAllFields = (field: any) => {
    const requiredProperties = ['identification', 'name', 'lastName', 'mail', 'phoneNumber']
    for (const key of requiredProperties) {
      if (!field[key]) {
        return false
      }
    }
    return true
  }

  return {
    fieldErrors,
    setFieldErrors,
    noAllFields,
    setCompanyInformation,
    validateCompanyInformation,
  }
}
